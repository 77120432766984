import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./Registration.css";

const ForgotPassword = () => {
    const { t } = useTranslation();

    const [isSent, setIsSent] = useState(false);
    const [email, setEmail] = useState('');
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            if (!validateEmail(email)) {
                throw new Error("Email is not valid")
            }
            const data = { email };
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/send-reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error("Network response was not ok")
            }

            const result = await response.json();

            setIsSent(true)
        } catch (error) {
            console.error('Error:', error);
            // Show error toast
            toast.error(`${t('invalidEmail')}`);
        }
    }

    return (
        !isSent ? <div className="regiComponent">
            <div className="regiBox">
                <h1>LatenceTech Online</h1>
                <form onSubmit={submit}>
                    <input
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder={t('email')}
                        required />

                    <button type='submit'>{t('resetPassword')}</button>
                </form>
            </div>
            <div className="login-link">
                <p><Link to="/login">{t('cancel')}</Link></p>
            </div>
        </div> : <div className="regiComponent">
            <div className="regiBox">
                <h1>LatenceTech Online</h1>
                <p>{t('recovery')} {email}</p>
            </div>
        </div>
    )
}

export default ForgotPassword;