import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './NetworkStatusSummary.css';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';

const Volatility = ({ timePeriod, refreshFrequency, agentId }) => {
  const [volatilityValue, setVolatility] = useState(null);
  const intervalIdRef = useRef(null);
  // const [debugInfo, setDebugInfo] = useState('');
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const getValueClass = (value) => {
    if (value < 5) {
      return 'low-vol';
    } else if (value >= 5 && value <= 10) {
      return 'medium-vol';
    } else if (value >= 10 && value <= 20) {
      return 'high-vol';
    } else {
      return 'huge-vol';
    }
  };

  const fetchVolatility = async () => {
    const volatilityQuery = `
    import "regexp"
    
    data = from(bucket: "LatenceTech")
      |> range(start: -${parseTimeRange(timePeriod)})
      |> filter(fn: (r) => r.agentID == "${agentId}")
      |> filter(fn: (r) => 
          r["_measurement"] == "twamp_result" or 
          r["_measurement"] == "icmp_result" or 
          r["_measurement"] == "udp_result" or 
          r["_measurement"] == "tcp_result" or 
          r["_measurement"] == "http_result"
        )
      |> filter(fn: (r) => r["_field"] == "delay")
      |> keep(columns: ["_time", "_value"])
    
    mini = data
      |> min(column: "_value")
      |> set(key: "for_pivot", value: "0")
      |> set(key: "_field", value: "mini")
    
    maxi = data
      |> max(column: "_value")
      |> set(key: "for_pivot", value: "0")
      |> set(key: "_field", value: "maxi")
    
    union(tables: [maxi, mini])
      |> pivot(rowKey: ["for_pivot"], columnKey: ["_field"], valueColumn: "_value")
      |> map(fn: (r) => ({ r with _value: ((r.maxi - r.mini) / (r.maxi + r.mini)) * 100.0}))
      |> keep(columns: ["_value"])`;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: volatilityQuery
      });
      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n');
        const valueLine = lines.find(line => line.includes('_result'));
        if (valueLine) {
          const rawValue = parseFloat(valueLine.split(',')[3]);
          const value = parseFloat(rawValue.toFixed(1));
          setVolatility(value);
          return value;
        }
        return 0;
      } else {
        console.error('Failed to fetch volatility:', response.status);
        return 0;
      }
    } catch (error) {
      console.error('Error fetching volatility:', error);
      return 0;
    }
  };


  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchVolatility();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchVolatility();
    }, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [agentId, refreshFrequency, timePeriod]);


  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('volatility')}</Typography>
        <Typography variant="h4" className={getValueClass(volatilityValue)}>{volatilityValue}%</Typography>
      </Paper>
    </Box>
  );
};

export default Volatility;