import React, { useEffect, useState } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import '../LifbePage/Lifbe.css';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const TcpBandwidthUpload = ({ timePeriod, agentId }) => {
  const [tcpBandwidthUpload, setTcpBandwidthUpload] = useState(null);
  const { auth: { customer_id } } = useAuth();
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchTcpBandwidthUpload = async () => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -12h)
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "iperf_tcp_result")
        |> filter(fn: (r) => r["_field"] == "senderBPS")
        |> keep(columns: ["_time", "_value"])
        |> sort(columns: ["_time"])
        |> last()
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n');
        const lastLine = lines[lines.length - 1];
        const value = lastLine.split(',')[4];
        const value2 = parseFloat(parseFloat(value / 1000000).toFixed(2));
        setTcpBandwidthUpload(value2);
      } else {
        console.error(`Error fetching latest TCP Bandwidth Upload data: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching latest TCP Bandwidth Upload data:`, error);
    }
  };

  useEffect(() => {
    fetchTcpBandwidthUpload();
  }, [timePeriod, agentId]);

  return (
    <Box className="throughput">
      <Paper className="throughput-box">
        <Typography variant="h5">{t('TCPupload')}</Typography>
        <Typography variant="h4" style={{ color: '#deb6f2' }}>
          {tcpBandwidthUpload !== null ? `${tcpBandwidthUpload} Mb/s` : 'No data'}
        </Typography>
      </Paper>
    </Box>
  );
};

export default TcpBandwidthUpload;