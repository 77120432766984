import React from "react";
import './Header.css'

function Header() {
    return (
        <h1 style={{ textAlign: 'center', }}>Fill the form to get your configuration file:</h1>
    )
}

export default Header
