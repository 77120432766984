import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import './NetworkStatusSummary.css';

const PacketLoss = ({ timePeriod, agentId, refreshFrequency }) => {
  const { auth: { customer_id } } = useAuth();
  const [data, setData] = useState({
    droppedPackets: null,
    totalPackets: null,
    packetLossRate: null
  });
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const getStatusClass = useCallback((value) => {
    if (value < 5) {
      return 'low-vol';
    } else if (value >= 5 && value <= 10) {
      return 'medium-vol';
    } else if (value >= 10 && value <= 20) {
      return 'high-vol';
    } else {
      return 'huge-vol';
    }
  }, []);

  const fetchPacketCounts = useCallback(async (measurementSuffix) => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) =>
            (r._measurement == "https_${measurementSuffix}" or
            r._measurement == "http_${measurementSuffix}" or
            r._measurement == "tcp_${measurementSuffix}" or
            r._measurement == "udp_${measurementSuffix}" or
            r._measurement == "icmp_${measurementSuffix}" or
            r._measurement == "twamp_${measurementSuffix}") and
            r._field == "sessionID" and
            r.agentID == "${agentId}" and
          r.customerID == "${customer_id}"
        )
        |> keep(columns: ["_time", "_value"])
        |> count()
    `;

    // line 53 removed - |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: query
      });
      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\r\n');
        return lines.length > 1 ? parseInt(lines[1].split(',')[3]) : 0;
      }
      console.error(`Failed to fetch ${measurementSuffix} packet count:`, response.status);
      return 0;
    } catch (error) {
      console.error(`Error fetching ${measurementSuffix} packet count:`, error);
      return 0;
    }
  }, [agentId, timePeriod]);

  const calculatePacketLossRate = useCallback((droppedPackets, totalPackets) => {
    if (totalPackets === 0) return 0;
    return parseFloat(((droppedPackets / (totalPackets + droppedPackets)) * 100).toFixed(2));
  }, []);

  const fetchDataAndUpdatePacketLoss = useCallback(async () => {
    const droppedPackets = await fetchPacketCounts("drop");
    const totalPackets = await fetchPacketCounts("result");
    const packetLossRate = calculatePacketLossRate(droppedPackets, totalPackets);

    setData({
      droppedPackets,
      totalPackets,
      packetLossRate
    });
  }, [fetchPacketCounts, calculatePacketLossRate]);

  useEffect(() => {
    fetchDataAndUpdatePacketLoss();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdatePacketLoss, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdatePacketLoss, refreshFrequency]);

  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('packetLoss')}</Typography>
        <Typography variant="h4" className={getStatusClass(data.packetLossRate)}>
          {data.packetLossRate !== null ? `${data.packetLossRate}%` : '%'}
        </Typography>
      </Paper>
    </Box>
  );
};

export default PacketLoss;
