import React, { createContext, useState, useContext } from 'react';

const AgentContext = createContext();

export const useAgent = () => useContext(AgentContext);

export const AgentProvider = ({ children }) => {
    const [selectedAgent, setSelectedAgent] = useState();

    const getAgentId = () => selectedAgent || 1;

    const updateAgentId = (newAgentId) => {
        setSelectedAgent(newAgentId);
    };

    return (
        <AgentContext.Provider value={{ selectedAgent, getAgentId, updateAgentId }}>
            {children}
        </AgentContext.Provider>
    );
};
