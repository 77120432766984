import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from 'jwt-decode';
const ProtectedRoute = ({ children }) => {
    const { auth } = useAuth();
    const location = useLocation();

    // Store the current location path in localStorage before navigating away
    if (location.pathname !== '/refresh-token') {
        localStorage.setItem('lastVisitedPath', location.pathname);
    }

    if (!auth || !auth.AuthToken) {
        return <Navigate to="/refresh-token" replace />;
    }

    if (auth.AuthToken) {
        const decodedToken = jwtDecode(auth.AuthToken);
        const currentTime = Date.now() / 1000;
        const licenseExpiration = new Date(decodedToken.LicenseExp).getTime() / 1000; // Convert ISO string to timestamp in seconds

        if (decodedToken.exp < currentTime) {
            return <Navigate to="/refresh-token" state={{ from: location }} replace />;
        }

        if (!decodedToken.IsLicensed || licenseExpiration < currentTime) {
            return <Navigate to="/license-gate" replace />;
        }
    }

    return children;
};

export default ProtectedRoute;
