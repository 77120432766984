import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from '../../hooks/useAuth';

const RefreshToken = () => {
    const navigate = useNavigate();
    const refresh = useRefreshToken();
    const { setAuth } = useAuth();

    useEffect(() => {
        const refreshAndNavigate = async () => {
            try {
                await refresh();

                // Retrieve the last visited path from localStorage
                const lastVisitedPath = localStorage.getItem('lastVisitedPath') || '/';

                // Navigate back to the last visited path after refreshing
                navigate(lastVisitedPath, { replace: true });
            } catch (error) {
                setAuth({});
                navigate("/login", { replace: true }); // Redirect to login if refresh fails
            }
        };

        refreshAndNavigate();
    }, []);

    return <div>Refreshing token, please wait...</div>;
};

export default RefreshToken;
