import React, { useEffect, useState, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import { Box, Typography } from '@mui/material';
import './Heatmap.css';
import HeatmapBox from './HeatmapBox'
import { TimePeriodSelect, RefreshFrequencySelect } from '../TimeUtils';
import { AgentIdInput } from '../agentIdManager';
import { useAgent } from '../../../context/AgentProvider';
import { useTranslation } from 'react-i18next';

const Heatmap = () => {
  // const [agentId, setAgentId] = useState('50');
  const [timePeriod, setTimePeriod] = useState('1hour');
  const [refreshFrequency, setRefreshFrequency] = useState('5seconds');
  const { selectedAgent, getAgentId, updateAgentId } = useAgent();
  const [currentAgentId, setCurrentAgentId] = useState(getAgentId());
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentAgentId(getAgentId());
  }, [selectedAgent]);

  const handleFetchData = (agentId) => {
    setCurrentAgentId(agentId);
    updateAgentId(agentId);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleRefreshFrequencyChange = (e) => {
    setRefreshFrequency(e.target.value);
  };

  return (
    <Box className="heatmap">
      <Typography variant="h3" className="page-heading">{t('heatmapTitle')}</Typography>
      <Box className="select-container">
        <TimePeriodSelect
          value={timePeriod}
          onChange={handleTimePeriodChange}
        />
        <RefreshFrequencySelect
          value={refreshFrequency}
          onChange={handleRefreshFrequencyChange}
        />
      </Box>
      <AgentIdInput onSubmit={handleFetchData} />

      <HeatmapBox timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />

    </Box>
  );
};

export default Heatmap;
