import useAuth from './useAuth';

const useFetchPrivate = () => {
    const { auth, setAuth } = useAuth();

    const fetchWithRetry = async (url, options) => {
        const tryRequest = async (isRetry = false) => {
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${auth?.AuthToken}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 403 && !isRetry) {
                const newAccessToken = await refreshAuthToken();
                if (newAccessToken) {
                    // Retry with the new access token
                    return tryRequest(true);
                }
            }

            return response; // Return the final response, even if it's 403 again
        };

        return tryRequest();
    };

    const refreshAuthToken = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/refresh`, {
                method: 'GET',
                credentials: 'include'  // Ensures cookies like refresh tokens are sent
            });

            if (response.ok) {
                const data = await response.json();
                setAuth(prev => ({
                    ...prev,
                    AuthToken: data.AuthToken
                }));
                return data.AuthToken;
            } else {
                throw new Error('Failed to refresh token');
            }
        } catch (error) {
            console.error('Error refreshing auth token:', error);
            return null;
        }
    };

    return fetchWithRetry;
};

export default useFetchPrivate;