import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import "./Registration.css";

const Login = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setAuth } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const submit = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (!validateEmail(email)) {
            setEmailError('Invalid Email');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (isValid) {
            const data = {
                email,
                password
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();

                const AuthToken = result.AuthToken;
                const customer_id = result.customerID;

                setAuth({ AuthToken, customer_id });

                // Decode the JWT to get the user data
                const userData = JSON.parse(atob(AuthToken.split('.')[1]));
                // Navigate based on user license
                if (userData.IsLicensed) {
                    navigate('/');
                } else {
                    navigate('/license-gate');
                }
            } catch (error) {
                console.error('Error:', error);
                // Show error toast
                toast.error(`${t('invalidLogin')}`);
            }
        }
    }

    return (
        <div className="regiComponent">
            <div className="regiBox">
                <h1>LatenceTech Online</h1>
                <form onSubmit={submit}>
                    <input
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder={t('email')}
                        required />
                    {emailError && (<span className='ErrorSpan'>{t('invalidEmail')}</span>)}

                    <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder={t('password')}
                        required />
                    {passwordError && (<span className='ErrorSpan'>{t('invalidPassword')}</span>)}

                    <button type='submit'>{t('loginButton')}</button>
                </form>
                <div className="login-link">
                    <p><Link to="/forgot-password">{t('forgotPassword')}</Link></p>
                </div>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="login-link">
                <p>{t('noAccount')} <Link to="/license-selector">{t('registerHere')}</Link></p>
            </div>
        </div>
    )
}

export default Login;
