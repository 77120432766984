import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import './Registration.css';

const Registration = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setAuth } = useAuth();
    const { REACT_APP_BACKEND, REACT_APP_STRIPE } = process.env;

    const [formData, setFormData] = useState({
        CompanyName: '',
        Country: '',
        FirstName: '',
        LastName: '',
        Password: '',
        AdminEmail: '',
        termsAccepted: false
    });

    const [errors, setErrors] = useState({});
    const [selectedLicense, setSelectedLicense] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);

    useEffect(() => {
        const licenseType = sessionStorage.getItem('SelectedLicenseType');
        if (!licenseType) {
            navigate('/license-selector');
        } else {
            setSelectedLicense(licenseType);
        }
    }, [navigate]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateInputs = () => {
        let isValid = true;
        let newErrors = {};

        if (!validateName(formData.CompanyName)) {
            newErrors.companyName = t('invalidCompanyName');
            isValid = false;
        }

        if (!formData.Country) {
            newErrors.country = t('invalidCountry');
            isValid = false;
        }

        if (!validateName(formData.FirstName)) {
            newErrors.firstName = t('invalidName');
            isValid = false;
        }

        if (!validateName(formData.LastName)) {
            newErrors.lastName = t('invalidName');
            isValid = false;
        }

        if (!validatePassword(formData.Password)) {
            newErrors.password = t('invalidPassword');
            isValid = false;
        }

        if (!validateEmail(formData.AdminEmail)) {
            newErrors.adminEmail = t('invalidEmail');
            isValid = false;
        }

        if (!formData.termsAccepted) {
            newErrors.termsAccepted = t('termsRequired');
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleCaptchaVerification = (token) => {
        setCaptchaToken(token);
    };

    const handleCaptchaExpire = () => {
        setCaptchaToken(null); // Reset the token when it expires
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validateName = (name) => /^[a-zA-Z0-9-.\s]{1,60}$/.test(name);
    const validatePassword = (password) => password.length >= 8 && password.length <= 60;

    const registerUser = async () => {
        const response = await fetch(`${REACT_APP_BACKEND}/userGroups/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...formData, LicenseType: selectedLicense })
        });
        return response;
    };

    const loginUser = async () => {
        const response = await fetch(`${REACT_APP_BACKEND}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: formData.AdminEmail, password: formData.Password })
        });
        return response;
    };

    const handleSubscription = async (AuthToken) => {
        const stripe = await loadStripe(REACT_APP_STRIPE);
        const body = { planIdentifier: selectedLicense };

        const stripeResponse = await fetch(`${REACT_APP_BACKEND}/licenses/purchase`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthToken}`
            },
            body: JSON.stringify(body)
        });

        const session = await stripeResponse.json();
        return stripe.redirectToCheckout({ sessionId: session.id });
    };

    const submit = async (e) => {
        e.preventDefault();

        if (!validateInputs()) return;
        if (!captchaToken) {
            toast.error(t('noCaptcha'));
            return;
        }

        try {
            const registrationResponse = await registerUser();
            if (!registrationResponse.ok) {
                const errorData = await registrationResponse.json();
                if (registrationResponse.status === 400 && errorData.error === 'Email is already registered') {
                    toast.error(t('emailInUse'));
                } else {
                    throw new Error('Internal server error');
                }
            } else {
                toast.success(t('defaultSuccess'));
                const loginResponse = await loginUser();
                if (!loginResponse.ok) throw new Error('Login failed');

                const { AuthToken, customerID } = await loginResponse.json();
                if (!AuthToken) throw new Error('AuthToken not provided after login');
                setAuth({ AuthToken, customerID });

                if (selectedLicense !== 'Free') {
                    await handleSubscription(AuthToken);
                } else {
                    const newLicense = await fetch(`${REACT_APP_BACKEND}/licenses/generate`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${AuthToken}`
                        },
                        body: JSON.stringify({ planIdentifier: selectedLicense })
                    });
                    const { AuthToken: newAuthToken } = await newLicense.json();
                    setAuth((prev) => ({ ...prev, AuthToken: newAuthToken }));
                }
                sessionStorage.removeItem("SelectedLicenseType");
                navigate('/');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(t('defaultError'));
        }
    };

    const renderError = (field) => errors[field] && <span className='ErrorSpan'>{errors[field]}</span>;

    return (
        <div className="regiComponent">
            <div className="regiBox">
                <h1>LatenceTech Online</h1>
                <form onSubmit={submit}>
                    <input
                        type="text"
                        name="CompanyName"
                        value={formData.CompanyName}
                        onChange={handleInputChange}
                        placeholder={t('companyName')}
                        required
                    />
                    {renderError('companyName')}

                    <input
                        type="email"
                        name="AdminEmail"
                        value={formData.AdminEmail}
                        onChange={handleInputChange}
                        placeholder={t('email')}
                        required
                    />
                    {renderError('adminEmail')}

                    <select
                        name="Country"
                        value={formData.Country}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="" disabled>{t('selectCountry')}</option>
                        <option value="Canada">Canada</option>
                        <option value="US">United States</option>
                        <option value="UK">United Kingdom</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="Spain">Spain</option>
                        <option value="Sweden">Sweden</option>
                        <option value="South Africa">South Africa</option>
                    </select>
                    {renderError('country')}

                    <input
                        type="text"
                        name="FirstName"
                        value={formData.FirstName}
                        onChange={handleInputChange}
                        placeholder={t('firstName')}
                        required
                    />
                    {renderError('firstName')}

                    <input
                        type="text"
                        name="LastName"
                        value={formData.LastName}
                        onChange={handleInputChange}
                        placeholder={t('lastName')}
                        required
                    />
                    {renderError('lastName')}

                    <input
                        type="password"
                        name="Password"
                        value={formData.Password}
                        onChange={handleInputChange}
                        placeholder={t('password')}
                        required
                    />
                    {renderError('password')}

                    <label htmlFor="terms" style={{ display: 'flex', gap: '10px' }}>
                        <Link to="https://www.latencetech.com/general-5">{t('termsOfService')}</Link>
                        <Link to="https://www.latencetech.com/eula">{t('eula')}</Link>
                    </label>
                    <div style={{ marginBottom: '10px' }}>
                        <input
                            type="checkbox"
                            name="termsAccepted"
                            checked={formData.termsAccepted}
                            onChange={handleInputChange}
                        />
                        <label>{t('acceptTerms')}</label>
                    </div>
                    {renderError('termsAccepted')}

                    <button type="submit">{t('registerButton')}</button>
                    <div style={{ alignSelf: 'center', marginTop: '7px' }}><HCaptcha
                        sitekey="30b271b8-2a34-4d90-9e54-0c86a6e85836"
                        onVerify={handleCaptchaVerification}
                        onExpire={handleCaptchaExpire} /></div>

                </form>
            </div>
            <div className="login-link">
                <p>{t('alreadyHaveAccount')} <Link to="/login">{t('loginHere')}</Link></p>
            </div>
        </div>
    );
};

export default Registration;
