import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import './LatencyForecast.css';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const ProjectedConfidenceLevel = ({ timePeriod, agentId, refreshFrequency }) => {
  const [confidenceLevel, setConfidenceLevel] = useState(null);
  const intervalIdRef = useRef(null);
  const { auth: { customer_id } } = useAuth();
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchConfidenceLevel = useCallback(async () => {
    const query = `
      import "influxdata/influxdb/schema"
      import "math"
      import "date"

      window_length=5m
      window_shift=30s
      forecast_length=1m

      data = from(bucket: "LatenceTech")
      |> range(start: date.sub(d: window_length, from: -${parseTimeRange(timePeriod)}))
      |> filter(fn: (r) => r["_field"] == "delay")
      |> filter(fn: (r) => r["_measurement"] == "icmp_result"
          or r["_measurement"] == "udp_result"
          or r["_measurement"] == "tcp_result"
          or r["_measurement"] == "twamp_result"
          or r["_measurement"] == "http_result"
          or r["_measurement"] == "https_result")
      |> filter(fn: (r) => r["agentID"] == "${agentId}" and
          r.customerID == "${customer_id}")
      |> drop(columns: ["_measurement"])

      windows = data
      |> window(every: window_shift, period: window_length)

      mean = windows
      |> mean(column: "_value")
      |> duplicate(column: "_stop", as: "_time")
      |> set(key: "_field", value: "mean")
      |> keep(columns: ["_time", "_field", "_value"])
      |> window(every: inf)
      |> timeShift(duration: forecast_length)
      |> range(start: -${parseTimeRange(timePeriod)}, stop: date.add(d: forecast_length, to: now()))

      stddev = windows
      |> stddev(column: "_value", mode: "population")
      |> duplicate(column: "_stop", as: "_time")
      |> set(key: "_field", value: "stddev")
      |> keep(columns: ["_time", "_field", "_value"])
      |> window(every: inf)
      |> timeShift(duration: forecast_length)
      |> range(start: -${parseTimeRange(timePeriod)}, stop: date.add(d: forecast_length, to: now()))

      max = union(tables:[mean, stddev])
      |> schema.fieldsAsCols()
      |> map(fn: (r) => ({ r with _value: (r.mean + r.stddev)}))
      |> set(key: "_field", value: "max")
      |> keep(columns: ["_time", "_field", "_value"])
      
      min = union(tables:[mean, stddev])
      |> schema.fieldsAsCols()
      |> map(fn: (r) => ({ r with _value: math.mMax(x: 0., y: r.mean - r.stddev)}))
      |> set(key: "_field", value: "min")
      |> keep(columns: ["_time", "_field", "_value"])
      
      union(tables: [min, max])
      |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> map(fn: (r) => ({ r with _value: (1.0 - (r.max - r.min) / r.max) * 100.0}))
      |> keep(columns: ["_time", "_value"])`;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: query
      });
      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n');
        const lastLine = lines[lines.length - 1];
        const value = lastLine.split(',')[4];
        return parseFloat(parseFloat(value).toFixed(2));
      }
    } catch (error) {
      console.error('Error fetching confidence level:', error);
    }
    return null;
  }, [agentId, timePeriod]);

  const fetchDataAndUpdate = useCallback(async () => {
    const level = await fetchConfidenceLevel();
    setConfidenceLevel(level);
  }, [fetchConfidenceLevel]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);

  return (
    <Box className="latency-forecast">
      <Paper className="forecast-box">
        <Typography variant="h5">{t('confidenceLevel')}</Typography>
        <Typography variant="h4">{confidenceLevel !== null ? `${confidenceLevel}%` : 'No data'}</Typography>
      </Paper>
    </Box>
  );
};

export default ProjectedConfidenceLevel;