import React, { useState, useEffect, useRef } from 'react';
import './ChatBot.css';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const ChatBot = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chatId, setChatId] = useState(null);
  const messagesEndRef = useRef(null);
  const { auth } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    createNewChat();
  }, []);

  const createNewChat = async () => {
    try {
      console.log('Sending request to create new chat');
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/chat/new`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title: 'New Chat' }),
      });

      if (!response.ok) {
        const errorBody = await response.text();
        console.error('Error response:', response.status, errorBody);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
      }

      const data = await response.json();
      console.log("New chat created, full response:", data);
      if (!data._id) {
        throw new Error('No chat ID returned from server');
      }
      setChatId(data._id);
    } catch (error) {
      console.error('Error creating new chat:', error);
      setError(`Error creating new chat: ${error.message}`);
    }
  };

  useEffect(() => {
    if (chatId) {
      console.log("Chat ID set, fetching messages for chatId:", chatId);
      fetchMessages();
    }
  }, [chatId]);

  const fetchMessages = async () => {
    if (!chatId) return;

    setIsLoading(true);
    setError(null);
    try {
      if (!auth?.AuthToken) {
        throw new Error('No authentication token found');
      }
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/chat/${chatId}/messages`, {
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Fetched messages:", data);
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError(`Error fetching messages: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const sendMessage = async () => {
    if (!input.trim() || !chatId) {
      console.log("Input is empty or chatId is missing, not sending message");
      return;
    }

    console.log("Sending message for chatId:", chatId);
    const newMessage = { role: 'user', content: input };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    setIsLoading(true);
    setError(null);

    try {
      if (!auth?.AuthToken) {
        throw new Error('No authentication token found');
      }
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/chat/${chatId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: input,
          context: messages.map(msg => ({ role: msg.role, content: msg.content }))
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let assistantMessage = { role: 'assistant', content: '' };

      setMessages(prevMessages => [...prevMessages, assistantMessage]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') break;

            try {
              const parsed = JSON.parse(data);
              if (parsed.content) {
                assistantMessage.content += parsed.content;
                setMessages(prevMessages => {
                  const newMessages = [...prevMessages];
                  newMessages[newMessages.length - 1] = { ...assistantMessage };
                  return newMessages;
                });
              }
            } catch (error) {
              console.error('Error parsing SSE message:', error);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError(`Error sending message: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  if (!chatId) {
    return <div>{t('newChat')}</div>;
  }

  return (
    <div className="chat-interface">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message-container ${message.role}`}>
            <div className="message-header">
              {message.role === 'assistant' ? 'Latencetech Bot' : 'You'}
            </div>
            <div className="message">
              {message.content}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="message-container assistant">

          </div>
        )}
        {error && <div className="message system error">{error}</div>}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="chat-input-form">
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type your message here..."
          disabled={isLoading}
          className="chat-input-field"
        />
        <button type="submit" disabled={isLoading || !input.trim()} className="chat-send-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default ChatBot;

// <div className="message-header">GPT</div>
// <div className="message">Thinking...</div>