import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const StripeSuccessHandler = () => {
    const navigate = useNavigate();
    const { setAuth, auth } = useAuth();

    // Function to fetch the AuthToken from the backend
    async function fetchAuthToken() {
        if (!auth?.AuthToken) {
            console.log('AuthToken is not available yet.');
            return; // Prevent fetch if AuthToken is not available
        }

        try {
            console.log(`${process.env.REACT_APP_BACKEND}/licenses/getAuthToken`)
            console.log(auth.AuthToken);
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/licenses/getAuthToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.AuthToken}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch AuthToken');
            }

            const { AuthToken: newAuthToken } = await response.json();
            console.log("New AuthToken: ", newAuthToken);
            setAuth((prev) => ({ ...prev, AuthToken: newAuthToken }));

            navigate('/'); // Redirect to the homepage
        } catch (error) {
            console.error('Error fetching AuthToken:', error);
        }
    }

    // Call the function when AuthToken becomes available
    useEffect(() => {
        console.log("useEffect triggered, checking AuthToken...");
        fetchAuthToken(); // Fetch the new AuthToken
    }, []);

    return null; // This component doesn't need to render anything
}

export default StripeSuccessHandler;
