import React, { useState } from 'react';
import './Settings.css';
import { Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const Settings = () => {
  const [refreshTime, setRefreshTime] = useState('5s'); // Default value set to 5s
  const [timeRange, setTimeRange] = useState('5min'); // Default value set to 5min
  const [agent, setAgent] = useState('1'); // Default value set to 1
  const [allowRemoteAccess, setAllowRemoteAccess] = useState(false);
  const [allowPdfReports, setAllowPdfReports] = useState(false);
  const [roles, setRoles] = useState([
    { name: 'Editor', permissions: { addDelete: 'Yes', move: 'Yes', customPages: 'Yes', addUser: 'No' } },
    { name: 'Viewer', permissions: { addDelete: 'No', move: 'No', customPages: 'No', addUser: 'No' } },
    { name: 'Invite Only', permissions: { addDelete: 'No', move: 'No', customPages: 'No', addUser: 'Yes' } },
  ]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');
  const [apiKey, setApiKey] = useState(uuidv4());

  const handleRefreshTimeChange = (e) => {
    setRefreshTime(e.target.value);
  };

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
  };

  const handleAgentChange = (e) => {
    setAgent(e.target.value);
  };

  const handleAllowRemoteAccessChange = (e) => {
    setAllowRemoteAccess(e.target.checked);
  };

  const handleAllowPdfReportChange = (e) => {
    setAllowPdfReports(e.target.checked);
  };

  const handleSaveSettings = () => {
    // Logic to save settings (for now, just console.log)
    console.log({
      refreshTime,
      timeRange,
      agent,
      allowRemoteAccess,
      allowPdfReports,
      apiKey,
    });
  };

  const handlePermissionChange = (roleIndex, permission, value) => {
    const updatedRoles = [...roles];
    updatedRoles[roleIndex].permissions[permission] = value;
    setRoles(updatedRoles);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewRoleName('');
  };

  const handleAddCustomRole = () => {
    setRoles([...roles, { name: newRoleName, permissions: { addDelete: 'No', move: 'No', customPages: 'No', addUser: 'No' } }]);
    handleDialogClose();
  };

  const handleGenerateApiKey = () => {
    setApiKey(uuidv4());
  };

// <Button variant="contained" color="primary" onClick={handleGenerateApiKey} style={{ marginTop: '10px', marginBottom: '20px' }}>
//   Generate New API Key
// </Button>

  return (
    <Box className="settings">
      <Typography variant="h3" className="page-heading">Settings</Typography>
      <Paper className="settings-paper">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel style={{ color: 'white', fontSize: "20px" }}>Default Refresh Time</InputLabel>
              <Select
                value={refreshTime}
                onChange={handleRefreshTimeChange}
                label="Default Refresh Time"
                className="white-input"
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#ffffff',
                      color: 'black',
                      borderRadius: '10px',
                      fontSize: '18px',
                    }
                  }
                }}
              >
                <MenuItem value="5s">5 seconds</MenuItem>
                <MenuItem value="10s">10 seconds</MenuItem>
                <MenuItem value="30s">30 seconds</MenuItem>
                <MenuItem value="1min">1 minute</MenuItem>
                <MenuItem value="5min">5 minutes</MenuItem>
                <MenuItem value="15min">15 minutes</MenuItem>
                <MenuItem value="30min">30 minutes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel style={{ color: 'white', fontSize: "20px" }}>Default Time Range</InputLabel>
              <Select
                value={timeRange}
                onChange={handleTimeRangeChange}
                label="Default Time Range"
                className="white-input"
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#ffffff',
                      color: 'black',
                      borderRadius: '10px',
                      fontSize: '18px',
                    }
                  }
                }}
              >
                <MenuItem value="5min">Last 5 Minutes</MenuItem>
                <MenuItem value="15min">Last 15 Minutes</MenuItem>
                <MenuItem value="30min">Last 30 Minutes</MenuItem>
                <MenuItem value="1h">Last 1 Hour</MenuItem>
                <MenuItem value="12h">Last 12 Hours</MenuItem>
                <MenuItem value="24h">Last 24 Hours</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Default Agent Selected"
              variant="outlined"
              value={agent}
              onChange={handleAgentChange}
              fullWidth
              InputLabelProps={{ style: { color: 'white', fontSize: "20px" } }}
              InputProps={{
                className: "white-input"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ color: 'white', marginBottom: '10px', marginTop: '10px' }}>Permissions</Typography>
            <TableContainer component={Paper} style={{ borderRadius: '10px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: 'white', backgroundColor: '#2a2e58', fontSize: '24px' }}>Role</TableCell>
                    <TableCell style={{ color: 'white', backgroundColor: '#2a2e58', fontSize: '20px' }}>Add/Delete Boxes</TableCell>
                    <TableCell style={{ color: 'white', backgroundColor: '#2a2e58', fontSize: '20px' }}>Move Boxes</TableCell>
                    <TableCell style={{ color: 'white', backgroundColor: '#2a2e58', fontSize: '20px' }}>Create Custom Pages</TableCell>
                    <TableCell style={{ color: 'white', backgroundColor: '#2a2e58', fontSize: '20px' }}>Add Users</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.map((role, roleIndex) => (
                    <TableRow key={role.name}>
                      <TableCell component="th" scope="row" style={{ color: 'white', backgroundColor: '#323769', fontSize: '18px' }}>{role.name}</TableCell>
                      <TableCell style={{ backgroundColor: '#323769' }}>
                        <Select
                          value={role.permissions.addDelete}
                          onChange={(e) => handlePermissionChange(roleIndex, 'addDelete', e.target.value)}
                          fullWidth
                          className="white-input"
                          MenuProps={{
                            PaperProps: {
                              style: {
                                backgroundColor: '#ffffff',
                                color: 'black',
                                borderRadius: '10px',
                                fontSize: '18px',
                              },
                            },
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#323769' }}>
                        <Select
                          value={role.permissions.move}
                          onChange={(e) => handlePermissionChange(roleIndex, 'move', e.target.value)}
                          fullWidth
                          className="white-input"
                          MenuProps={{
                            PaperProps: {
                              style: {
                                backgroundColor: '#ffffff',
                                color: 'black',
                                borderRadius: '10px',
                                fontSize: '18px',
                              },
                            },
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#323769' }}>
                        <Select
                          value={role.permissions.customPages}
                          onChange={(e) => handlePermissionChange(roleIndex, 'customPages', e.target.value)}
                          fullWidth
                          className="white-input"
                          MenuProps={{
                            PaperProps: {
                              style: {
                                backgroundColor: '#ffffff',
                                color: 'black',
                                borderRadius: '10px',
                                fontSize: '18px',
                              },
                            },
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#323769' }}>
                        <Select
                          value={role.permissions.addUser}
                          onChange={(e) => handlePermissionChange(roleIndex, 'addUser', e.target.value)}
                          fullWidth
                          className="white-input"
                          MenuProps={{
                            PaperProps: {
                              style: {
                                backgroundColor: '#ffffff',
                                color: 'black',
                                borderRadius: '10px',
                                fontSize: '18px',
                              },
                            },
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" color="primary" onClick={handleDialogOpen} style={{ marginTop: '10px', marginBottom: '20px' }}>
              Add Custom Role
            </Button>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
              <DialogTitle>Add Custom Role</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Role Name"
                  type="text"
                  fullWidth
                  value={newRoleName}
                  onChange={(e) => setNewRoleName(e.target.value)}
                  InputProps={{
                    style: {
                      color: 'black',
                      backgroundColor: '#ffffff'
                    }
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button className="team-delete" onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button className="team-add" onClick={handleAddCustomRole} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4" style={{ color: 'white', marginBottom: '10px' }}>API Key</Typography>
            <TextField
              variant="outlined"
              value={apiKey}
              fullWidth
              InputProps={{
                style: {
                  color: 'white',
                  backgroundColor: '#323769',
                  marginBottom: '20px'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ color: 'white', marginBottom: '10px' }}>Misc.</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowRemoteAccess}
                  onChange={handleAllowRemoteAccessChange}
                  color="primary"
                />
              }
              label="Allow Remote Access to Agents"
              style={{ color: 'white' }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowPdfReports}
                  onChange={handleAllowPdfReportChange}
                  color="primary"
                />
              }
              label="Allow PDF Reports Generation"
              style={{ color: 'white', marginBottom: '10px'}}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSaveSettings}>
              Save Settings
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Settings;

