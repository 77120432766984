import React from 'react';
import { useTranslation } from 'react-i18next';

export const parseTimeRange = (period) => {
  const periodMapping = {
    '5seconds': '5s',
    '10seconds': '10s',
    '30seconds': '30s',
    '1min': '1m',
    '5min': '5m',
    '15min': '15m',
    '30min': '30m',
    '1hour': '1h',
    '3hours': '3h',
    '6hours': '6h',
    '12hours': '12h',
    '24hours': '24h',
    '2days': '2d',
    '7days': '7d',
    '30days': '30d',
    '90days': '90d',
  };
  return periodMapping[period] || '5m';
};

export const getRefreshInterval = (frequency) => {
  switch (frequency) {
    case '5seconds': return 5000;
    case '10seconds': return 10000;
    case '30seconds': return 30000;
    case '1min': return 60000;
    case '5min': return 300000;
    case '15min': return 900000;
    case '30min': return 1800000;
    default: return 5000;
  }
};

export const customer_id = 115;

// Time Period Select Component
export const TimePeriodSelect = ({ value, onChange }) => {
  const { t } = useTranslation();
  const timePeriodOptions = [
    { value: '5min', label: t('5minutes') },
    { value: '15min', label: t('15minutes') },
    { value: '30min', label: t('30minutes') },
    { value: '1hour', label: t('1hour') },
    { value: '3hours', label: t('3hours') },
    { value: '6hours', label: t('6hours') },
    { value: '12hours', label: t('12hours') },
    { value: '24hours', label: t('24hours') },
    { value: '2days', label: t('2days') },
    { value: '7days', label: t('7days') }
  ];

  return (
    <div className="select-container">
      <h1>{t('displayLast')}:</h1>
      <select value={value} onChange={onChange}>
        {timePeriodOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

// Refresh Frequency Select Component
export const RefreshFrequencySelect = ({ value, onChange }) => {
  const { t } = useTranslation();
  const refreshFrequencyOptions = [
    { value: '5seconds', label: t('5seconds') },
    { value: '10seconds', label: t('10seconds') },
    { value: '30seconds', label: t('30seconds') },
    { value: '1min', label: t('1minute') },
    { value: '5min', label: t('5minutes') },
    { value: '15min', label: t('15minutes') },
    { value: '30min', label: t('30minutes') }
  ];

  return (
    <div className="select-container">
      <h1>{t('refresh')}:</h1>
      <select value={value} onChange={onChange}>
        {refreshFrequencyOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};


