import React, { useState } from 'react';
import Header from "../Header";
import { useTranslation } from 'react-i18next';
import './AgentForm.css'; // Import the same CSS file used by AgentForm

const ReflectorForm = () => {
const nodeServer = 'node-server.latence.ca';
//const nodeServer = '20.200.122.171';

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    reflectorID: '',
    licenseKey: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the form data to the server
      const response = await fetch(`https://${nodeServer}/reflector-file`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch or modify the file');
      }

      // Trigger the file download on the client side
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'lti_reflector.yml';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error:', error.message);
      // Handle the error, show a user-friendly message, etc.
    }
  };

  return (
    <div className="agent-form-container">
      <Header />
      <form onSubmit={handleSubmit} className="agent-form">
        <div className='formBoxTop'>
          <label>
            {t('reflectorId')}: <span style={{ color: '#eb1e1e' }}>{t('reflectorIdMustBeInteger')}</span>
            <input
              type="text"
              name="reflectorID"
              value={formData.reflectorID}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            {t('licenseKey')}:
            <input
              type="text"
              name="licenseKey"
              value={formData.licenseKey}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <button className='button-submit' type="submit">
          {t('download')}
        </button>
      </form>
    </div>

  );
};

export default ReflectorForm;

