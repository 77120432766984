import React, { useState, useRef, useEffect } from 'react';
// For the dropdown menu of the reflector
import Header from '../Header'
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import useFetchPrivate from '../../hooks/useFetchPrivate';
import './AgentForm.css'; // Add CSS file for styling

const AgentForm = () => {
  const defaultAgentLatency = '30';
  const defaultAgentStability = '95';
  const defaultIperfSessionInterval = '7200';
  const defaultTracerouteInterval = '300';
  const defaulthttpInterval = '2.0';
  const defaulthttpsInterval = '2.0';
  const defaultTcpInterval = '2.0';
  const defaultUdpInterval = '2.0';
  const defaultIcmpInterval = '2.0';
  const defaultTwampInterval = '1.0';
  const nodeServer = 'node-server.latence.ca';
  const defaultAnalyzerIp = process.env.REACT_APP_ANALYZER_IP;

  //const nodeServer = '20.200.122.171';
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const fetchPrivate = useFetchPrivate();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    agentID: '',
    analyzerIP: defaultAnalyzerIp,
    reflectorIP: '',
    licenseKey: '',
    agentName: '',
    agentHardware: '',
    networkType: '',
    networkName: '',
    agentAddress: '',
    agentGeo: '',
    agentDetails: '',
    agentLatency: defaultAgentLatency,
    agentStability: defaultAgentStability,
    iperfSessionInterval: defaultIperfSessionInterval,
    tracerouteInterval: defaultTracerouteInterval,
    httpInterval: defaulthttpInterval,
    httpsInterval: defaulthttpsInterval,
    tcpInterval: defaultTcpInterval,
    udpInterval: defaultUdpInterval,
    icmpInterval: defaultIcmpInterval,
    twampInterval: defaultTwampInterval,
  });

  const [isFetched, setIsFetched] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For the dropdown menu of the reflector
  const handleReflectorSelect = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      reflectorIP: value,
    }));
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchLicenseKey = async () => {
      try {
        const response = await fetchPrivate(`${process.env.REACT_APP_BACKEND}/account`, {
          method: 'GET'
        });

        if (response.ok) {
          const data = await response.json();
          setFormData((prevData) => ({
            ...prevData,
            licenseKey: data.licenseKey || ''
          }));
          setIsFetched(true);
        } else {
          console.error('Failed to fetch account data, status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      }
    };

    fetchLicenseKey();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the form data to the server
      const response = await fetch(`https://${nodeServer}/modify-and-download-file`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch or modify the file');
      }

      // Trigger the file download on the client side
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'lti_qos-agent.yml';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error:', error.message);
      // Handle the error, show a user-friendly message, etc.
    }
  };

  // For the dropdown menu of the reflector
  const reflectorOptions = [
    { name: 'Montreal', value: 'montreal-beacon.latence.ca' },
    { name: 'Paris', value: 'paris-beacon.latence.ca' },
    { name: 'North Central US', value: 'northcentral-beacon.latence.ca' },
    { name: 'Brazil', value: 'brazil-beacon.latence.ca' },
  ];

  return (
    <div className="agent-form-container">
      <Header />
      <form onSubmit={handleSubmit} className="agent-form">
        <div className='formBoxTop'>
          <label>
            {t('agentId')}: <span style={{ color: '#eb1e1e' }}>{t('agentIdMustBeInteger')}</span>
            <input
              type="text"
              name="agentID"
              value={formData.agentID}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            {t('analyzerIpAddress')}:
            <input
              type="text"
              name="analyzerIP"
              value={formData.analyzerIP}
              onChange={handleChange}
              placeholder={defaultAnalyzerIp}
              required
            />
          </label>
          <label>
            {t('reflectorIpAddress')}:
            <div className="combobox-container" ref={dropdownRef}>
              <input
                type="text"
                name="reflectorIP"
                value={formData.reflectorIP}
                onChange={handleChange}
                onFocus={() => setIsOpen(true)}
                required
                className="combobox-input"
              />
              {isOpen && (
                <ul className="combobox-options">
                  {reflectorOptions.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleReflectorSelect(option.value)}
                    >
                      {option.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </label>
          <label>
            {t('licenseKey')}:
            {isFetched ? <input
              type="text"
              name="licenseKey"
              value={formData.licenseKey}
              required
            /> : <input
              type="text"
              name="licenseKey"
              value={formData.licenseKey}
              onChange={handleChange}
              required
            />}
          </label>
        </div>

        <div className='mid'>
          <div className='formBoxA'>
            <h4>{t('metadataSection')}:</h4>
            <label>
              {t('agentName')}:
              <br />
              <input
                type="text"
                name="agentName"
                value={formData.agentName}
                onChange={handleChange}
                required
              />
            </label>
            <br />

            <label>
              {t('agentHardware')}:
              <br />
              <input
                type="text"
                name="agentHardware"
                value={formData.agentHardware}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              {t('expectedApplicationLatency')} <br /> ({t('inMilliseconds')}):
              <br />
              <input
                type="text"
                name="agentLatency"
                value={formData.agentLatency}
                onChange={handleChange}
                placeholder={defaultAgentLatency}
                required
              />
            </label>
            <br />

            <label>
              {t('expectedStability')} ({t('inPercentage')}):
              <br />
              <input
                type="text"
                name="agentStability"
                value={formData.agentStability}
                onChange={handleChange}
                placeholder={defaultAgentStability}
                required
              />
            </label>
            <br />

            <label>
              {t('networkType')}:
              <br />
              <input
                type="text"
                name="networkType"
                value={formData.networkType}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              {t('networkName')}:
              <br />
              <input
                type="text"
                name="networkName"
                value={formData.networkName}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              {t('agentAddress')} ({t('homeOfficeEtc')}):
              <br />
              <input
                type="text"
                name="agentAddress"
                value={formData.agentAddress}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              {t('agentGeolocation')} ({t('exampleCoordinates')}):
              <br />
              <input
                type="text"
                name="agentGeo"
                value={formData.agentGeo}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              {t('agentDetails')}:
              <br />
              <input
                type="text"
                name="agentDetails"
                value={formData.agentDetails}
                onChange={handleChange}
              />
            </label>
            <br />
          </div>

          <div className='formBox'>
            <h4>{t('optionalConfigurations')}:</h4>

            <label>
              {t('iperfSessionInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="iperfSessionInterval"
                value={formData.iperfSessionInterval}
                onChange={handleChange}
                placeholder={defaultIperfSessionInterval}
              />
            </label>
            <br />

            <label>
              {t('tracerouteInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="tracerouteInterval"
                value={formData.tracerouteInterval}
                onChange={handleChange}
                placeholder={defaultTracerouteInterval}
              />
            </label>
            <br />

            <label>
              {t('httpInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="httpInterval"
                value={formData.httpInterval}
                onChange={handleChange}
                placeholder={defaulthttpInterval}
              />
            </label>
            <br />

            <label>
              {t('httpsInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="httpsInterval"
                value={formData.httpsInterval}
                onChange={handleChange}
                placeholder={defaulthttpsInterval}
              />
            </label>
            <br />

            <label>
              {t('tcpInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="tcpInterval"
                value={formData.tcpInterval}
                onChange={handleChange}
                placeholder={defaultTcpInterval}
              />
            </label>
            <br />

            <label>
              {t('udpInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="udpInterval"
                value={formData.udpInterval}
                onChange={handleChange}
                placeholder={defaultUdpInterval}
              />
            </label>
            <br />

            <label>
              {t('icmpInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="icmpInterval"
                value={formData.icmpInterval}
                onChange={handleChange}
                placeholder={defaultIcmpInterval}
              />
            </label>
            <br />

            <label>
              {t('twampInterval')} ({t('inSeconds')}):
              <br />
              <input
                type="text"
                name="twampInterval"
                value={formData.twampInterval}
                onChange={handleChange}
                placeholder={defaultTwampInterval}
              />
            </label>
            <br />
          </div>
        </div>

        <button className='button-submit' type="submit">
          {t('download')}
        </button>
      </form >
    </div >
  );
};

export default AgentForm;

