import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Paper } from '@mui/material';
import './Homepage.css';

const Homepage = () => {


  return (
    <div className="homepage">
      <h2>Latency Analyzer</h2>
      <img src="https://cdn.prod.website-files.com/6655f22199620d07dc877bd5/6655f24edb3b01a40b2c23f9_favicon_logo_2.png" alt=""/>
    </div>
  );
};

export default Homepage;
