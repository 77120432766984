import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import './Lifbe.css';
import LifbeDownload from './LifbeDownload'
import LifbeUpload from './LifbeUpload'
import LifbeJitterDownload from './LifbeJitterDownload'
import LifbeJitterUpload from './LifbeJitterUpload'
import LifbeNetworkInterface from './LifbeNetworkInterface'
import LifbeNetworkSubtype from './LifbeNetworkSubtype'
import LifbeHistoryChart from './LifbeHistoryChart'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import { TimePeriodSelect, RefreshFrequencySelect } from '../TimeUtils';
import { AgentIdInput } from '../agentIdManager';
import { useAgent } from '../../../context/AgentProvider';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const Lifbe = () => {
  const { selectedAgent, getAgentId, updateAgentId } = useAgent();
  const [currentAgentId, setCurrentAgentId] = useState(getAgentId());
  const [timePeriod, setTimePeriod] = useState('12hours');
  const [refreshFrequency, setRefreshFrequency] = useState('5seconds');
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();


  useEffect(() => {
    // Initialize currentAgentId with the value from localStorage
    setCurrentAgentId(getAgentId());
  }, [selectedAgent]);

  const handleFetchData = (agentId) => {
    setCurrentAgentId(agentId);
    updateAgentId(agentId);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleRefreshFrequencyChange = (e) => {
    setRefreshFrequency(e.target.value);
  };

  return (
    <Box className="lifbe">
      <Typography variant="h3" className="page-heading">{t('lifbeTitle')}</Typography>
      <Box className="select-container">
        <TimePeriodSelect
          value={timePeriod}
          onChange={handleTimePeriodChange}
        />
        <RefreshFrequencySelect
          value={refreshFrequency}
          onChange={handleRefreshFrequencyChange}
        />
      </Box>

      <AgentIdInput onSubmit={handleFetchData} />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <LifbeDownload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={3}>
          <LifbeUpload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <LifbeJitterDownload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <LifbeJitterUpload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <LifbeNetworkInterface timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
          <LifbeNetworkSubtype timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={12}>
          <LifbeHistoryChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Lifbe;
