import React, { useEffect, useState } from 'react';
import { Typography, Paper, Box } from '@mui/material';
import { parseTimeRange } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import './Lifbe.css';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import { useTranslation } from 'react-i18next';

const LifbeNetworkSubtype = ({ timePeriod, agentId }) => {
  const { auth: { customer_id } } = useAuth();
  const [networkSubtype, setNetworkSubtype] = useState(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchNetworkSubtype = async () => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) =>
          r._measurement == "lifbe_result" and
          r._field == "sessionID" and
          r.agentID ==  "${agentId}" and
          r.customerID == "${customer_id}"
        )
        |> last()
        |> keyValues(keyColumns: ["networkType"])
        |> keep(columns: ["networkType"])
        |> group()
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });
      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n');
        if (lines.length > 1) {
          const lastLine = lines[lines.length - 1];
          const value = lastLine.split(',')[3];
          setNetworkSubtype(value.trim());
        } else {
          setNetworkSubtype('No data');
        }
      } else {
        console.error('Failed to fetch Network Subtype:', response.status);
        setNetworkSubtype('Error');
      }
    } catch (error) {
      console.error('Error fetching Network Subtype:', error);
      setNetworkSubtype('Error');
    }
  };

  useEffect(() => {
    fetchNetworkSubtype();
  }, [timePeriod, agentId]);

  return (
    <Box className="lifbe">
      <Paper className="throughput-box">
        <Typography variant="h6" style={{ color: '#8f3bb8' }}>
          {t('lifbeSubtype')}: {networkSubtype !== null ? networkSubtype : 'No data'}
        </Typography>
      </Paper>
    </Box>
  );
};

export default LifbeNetworkSubtype;