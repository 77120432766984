import React from 'react';
import './Training.css'
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


const Training = () => {
  const { t } = useTranslation();

  return (
    <Box className="training">
      <Typography variant="h3" className="page-heading">{t('trainingVideos')}</Typography>
      <h4>{t('installReflector')}</h4>
      <video width="840" height="510" src="../videos/reflector_install.mp4" type="video/mp4" controls autoplay />
      <h4>{t('installAgent')}</h4>
      <video width="840" height="510" src="../videos/agent_install.mp4" type="video/mp4" controls autoplay />
      <h4>{t('installAgentOnCradlepointModem')}</h4>
      <video width="840" height="510" src="../videos/cradlepoint-agent.mp4" type="video/mp4" controls autoplay />
    </Box>

  );
};

export default Training;