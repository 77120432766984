import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import useFetchPrivate from '../../hooks/useFetchPrivate';
import useAuth from '../../hooks/useAuth';

const ChangeSubscription = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        role: '',
        licenseType: '',
        licenseEx: '',
        customerID: '',
        subscription: '',
        licenseKey: ''
    });
    const fetchPrivate = useFetchPrivate();
    const { auth } = useAuth();

    const fetchAccountData = async () => {
        try {
            const response = await fetchPrivate(`${process.env.REACT_APP_BACKEND}/account`, {
                method: 'GET'
            });

            if (response.ok) {
                const data = await response.json();
                console.log("data:", data);
                setUserData({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    company: data.company,
                    role: data.role,
                    licenseType: data.licenseType || "",
                    licenseEx: data.licenseEx ? new Date(data.licenseEx).toLocaleDateString() : "",
                    subscription: data.subscription,
                    customerID: data.customerID,
                    licenseKey: data.licenseKey
                });
                console.log("user role:", data.role);
            } else {
                console.error('Failed to fetch account data, status:', response.status);
            }
        } catch (error) {
            console.error('Error fetching account data:', error);
        }
    };

    useEffect(() => {
        fetchAccountData().then(() => {
            if (userData.role && userData.role !== 'admin') {
                toast.error(`${t('invalidPermissions')}`);
                navigate('/');
            }
        });
    }, []);


    const handleLicenseSelection = async (licenseType) => {
        if (userData.licenseType === 'Free') {
            try {// Subscription logic
                const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE}`);
                const body = { planIdentifier: licenseType === 'Basic' ? 1 : 2 };

                const stripeResponse = await fetch(`${process.env.REACT_APP_BACKEND}/licenses/purchase`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth?.AuthToken}`
                    },
                    body: JSON.stringify(body)
                });
                const session = await stripeResponse.json();
                const result = await stripe.redirectToCheckout({ sessionId: session.id });

                if (result.error) {
                    console.error(result.error.message);
                    toast.error('An error occurred. Please try again later.');
                }
            } catch (error) {
                console.error('Error creating Stripe checkout session:', error);
                toast.error('An error occurred. Please try again later.');
            }
        } else {
            try {
                const body = { planIdentifier: licenseType };

                // Send request to update subscription plan
                const response = await fetchPrivate(`${process.env.REACT_APP_BACKEND}/licenses/change-plan`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                });

                if (response.ok) {
                    const data = await response.json();
                    toast.success('Subscription updated successfully!');
                    navigate('/');
                } else {
                    console.error('Failed to update subscription:', response.status);
                    toast.error(t('defaultError'));
                }
            } catch (error) {
                console.error('Error updating subscription:', error);
                toast.error(t('defaultError'));
            }
        }
    };

    return (
        <div className='pricing-component'>
            <h1>{t('pricingTitle')}</h1>
            <p className="subtitle">
                {t('pricingSubtitle')}
            </p>

            <div className="pricing-cards">

                <div className="pricing-card">
                    {userData.licenseType == 'Basic' && <span>{t('currentPlan')}</span>}
                    <h2>{t('basicPlan')}</h2>
                    <p className="price">{t('priceBasic')}</p>
                    <p className="duration">{t('durationMonthly')}</p>
                    <ul>
                        <li>{t('basicAgents')}</li>
                        <li>{t('users', { count: 5 })}</li>
                        <li>{t('accessPlatform')}</li>
                        <li>{t('openAPIs')}</li>
                        <li>{t('createQoSAgents')}</li>
                        <li>{t('updatesUpgrades')}</li>
                        <li>{t('onlineSupport')}</li>
                    </ul>
                    {userData.licenseType !== 'Basic' ?
                        <button onClick={() => handleLicenseSelection('Basic')}>{t('selectBasicSubscription')}</button>
                        : <button onClick={() => navigate('/')}>{t('cancel')}</button>}
                </div>

                <div className="pricing-card">
                    {userData.licenseType == 'Enterprise' && <span>{t('currentPlan')}</span>}
                    <h2>{t('enterprisePlan')}</h2>
                    <p className="price">{t('priceEnterprise')}</p>
                    <p className="duration">{t('durationMonthly')}</p>
                    <ul>
                        <li>{t('enterpriseAgents')}</li>
                        <li>{t('users', { count: 30 })}</li>
                        <li>{t('accessPlatform')}</li>
                        <li>{t('openAPIs')}</li>
                        <li>{t('createQoSAgents')}</li>
                        <li>{t('updatesUpgrades')}</li>
                        <li>{t('onlineSupport')}</li>
                    </ul>
                    {userData.licenseType !== 'Enterprise' ?
                        <button onClick={() => handleLicenseSelection('Enterprise')}>{t('selectEnterpriseSubscription')}</button>
                        : <button onClick={() => navigate('/')}>{t('cancel')}</button>}
                </div>
            </div>
        </div >

    );
};

export default ChangeSubscription;