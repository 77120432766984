import React, { useEffect, useState } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange } from '../TimeUtils';
import './Lifbe.css';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const LifbeJitterDownload = ({ timePeriod, agentId }) => {
  const [jitterDownload, setJitterDownload] = useState(null);
  const { auth: { customer_id } } = useAuth();
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchLatestJitterDownload = async () => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) => r._measurement == "lifbe_result" and r._field == "receiverJitter" and r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> keep(columns: ["_time", "_value"])
        |> sort(columns: ["_time"])
        |> last()
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n');
        const lastLine = lines[lines.length - 1];
        const value = lastLine.split(',')[4];
        const value2 = parseFloat(parseFloat(value).toFixed(2));
        setJitterDownload(value2);
      } else {
        console.error(`Error fetching latest download jitter data: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching latest download jitter data:`, error);
    }
  };

  useEffect(() => {
    fetchLatestJitterDownload();
  }, [timePeriod, agentId]);

  return (
    <Box className="lifbe">
      <Paper className="throughput-box">
        <Typography variant="h5">{t('lifbeDownloadJitter')}</Typography>
        <Typography variant="h4" style={{ color: '#1f60c4' }}>
          {jitterDownload !== null ? `${jitterDownload} ms` : 'No data'}
        </Typography>
      </Paper>
    </Box>
  );
};

export default LifbeJitterDownload;