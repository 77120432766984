import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import './Throughput.css'
import TcpBandwidthDownload from './TcpBandwidthDownload'
import TcpBandwidthUpload from './TcpBandwidthUpload'
import ThroughputChart from './ThroughputChart'
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import { TimePeriodSelect, RefreshFrequencySelect } from '../TimeUtils';
import { AgentIdInput } from '../agentIdManager';
import { useAgent } from '../../../context/AgentProvider';
import { useTranslation } from 'react-i18next';

const Throughput = () => {
  const { selectedAgent, getAgentId, updateAgentId } = useAgent();
  const { t } = useTranslation();

  const [currentAgentId, setCurrentAgentId] = useState(getAgentId());
  const [timePeriod, setTimePeriod] = useState('12hours');
  const [refreshFrequency, setRefreshFrequency] = useState('5seconds');
  // const intervalIdRef = useRef(null);


  useEffect(() => {
    // Initialize currentAgentId with the value from localStorage
    setCurrentAgentId(getAgentId());
  }, [selectedAgent]);

  const handleFetchData = (agentId) => {
    setCurrentAgentId(agentId);
    updateAgentId(agentId);
  };


  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleRefreshFrequencyChange = (e) => {
    setRefreshFrequency(e.target.value);
  };

  return (
    <Box className="throughput">
      <Typography variant="h3" className="page-heading">{t('throughput')}</Typography>
      <Box className="select-container">
        <TimePeriodSelect
          value={timePeriod}
          onChange={handleTimePeriodChange}
        />
        <RefreshFrequencySelect
          value={refreshFrequency}
          onChange={handleRefreshFrequencyChange}
        />
      </Box>

      <AgentIdInput onSubmit={handleFetchData} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TcpBandwidthDownload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={6}>
          <TcpBandwidthUpload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        {/* <Grid item xs={3}>
          <UdpBandwidthDownload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={3}>
          <UdpBandwidthUpload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid> */}
        <Grid item xs={12}>
          <ThroughputChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Throughput;
