import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Box, Typography } from '@mui/material';
import './Heatmap.css';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
const HEADERS = {
  'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
  'Accept': 'application/csv',
  'Content-type': 'application/vnd.flux'
};

// const parseTimeRange = (period) => {
//   const periodMapping = {
//     '5seconds': '5s',
//     '10seconds': '10s',
//     '30seconds': '30s',
//     '1min': '1m',
//     '5min': '5m',
//     '15min': '15m',
//     '30min': '30m',
//     '1hour': '1h',
//     '3hours': '3h',
//     '6hours': '6h',
//     '12hours': '12h',
//     '24hours': '24h',
//     '2days': '2d',
//     '7days': '7d',
//     '30days': '30d',
//     '90days': '90d',
//   };
//   return periodMapping[period] || '5m';
// };

const MapUpdater = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center && !isNaN(center[0]) && !isNaN(center[1])) {
      map.setView(center, 20);
    }
  }, [center, map]);
  return null;
};

// Custom icon
const customIcon = new L.Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=FBZG1TSLWn1Z&format=png&color=000000',
  iconSize: [10, 10],
  iconAnchor: [0, 34],
  popupAnchor: [-3, -76],
});

const HeatmapBox = ({ timePeriod, refreshFrequency, agentId }) => {
  const [locations, setLocations] = useState([]);
  const [center, setCenter] = useState([45.5019, -73.5674]);
  const [noData, setNoData] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const intervalIdRef = useRef(null);
  const { auth } = useAuth();
  const customer_id = auth.customer_id;
  const { t } = useTranslation();

  const fetchGeolocationData = async () => {
    if (!shouldFetch) return;

    const query = `
      latency = from(bucket: "LatenceTech")
      |> range(start: -${parseTimeRange(timePeriod)})
      |> filter(fn: (r) => r["_field"] == "delay")
      |> filter(fn: (r) => r["agentID"] == "${agentId}" and
          r.customerID == "${customer_id}")
      |> sort(columns: ["_time"], desc: true)
      |> keep(columns: ["_field", "_value", "_time", "agentID", "reflector"])
      |> set(key: "for_pivot", value: "0")

      geoloc = from(bucket: "LatenceTech")
      |> range(start: -${parseTimeRange(timePeriod)})
      |> filter(fn: (r) => r["_measurement"] == "geolocation")
      |> filter(fn: (r) => r["agentID"] == "${agentId}" and
          r.customerID == "${customer_id}")
      |> sort(columns: ["_time"], desc: true)
      |> keep(columns: ["_field", "_value", "_time", "agentID", "reflector"])
      |> set(key: "for_pivot", value: "0")

      union(tables: [latency, geoloc])
      |> aggregateWindow(every: 20s, fn: mean, createEmpty: false)
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> sort(columns: ["_time"], desc: true)
      |> rename(columns: {delay: "Value (ms)"})
      |> rename(columns: {_time: "Date & time"})
      |> keep(columns: ["Date & time", "Altitude", "Latitude", "Longitude", "Value (ms)"])
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line

        const results = lines.map((line, index) => {
          const columns = line.split(',').map(column => column.trim().replace('\r', ''));
          console.log(`Line ${index + 1}:`, columns); // Log each line for debugging
          return {
            time: columns[3],
            altitude: parseFloat(columns[4]),
            latitude: parseFloat(columns[5]),
            longitude: parseFloat(columns[6]),
            value: parseFloat(columns[7])
          };
        }).filter(location => !isNaN(location.latitude) && !isNaN(location.longitude));

        if (results.length > 0) {
          setLocations(results);
          const lastLocation = results[0];
          setCenter([lastLocation.latitude, lastLocation.longitude]);
          setNoData(false);
          setShouldFetch(false); // Stop fetching if data found
        } else {
          setLocations([]);
          setNoData(true);
          setShouldFetch(false); // Stop fetching if no data found
        }
      } else {
        console.error('Failed to fetch data:', response.statusText);
        setLocations([]);
        setNoData(true);
        setShouldFetch(false); // Stop fetching if error occurs
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLocations([]);
      setNoData(true);
      setShouldFetch(false); // Stop fetching if error occurs
    }
  };

  // useEffect(() => {
  //   fetchGeolocationData();
  // }, [agentId, timePeriod]);


  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchGeolocationData();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchGeolocationData();
    }, refreshInterval);

    return () => clearInterval(intervalIdRef.current);
  }, [agentId, refreshFrequency, timePeriod]);

  const lastLocation = locations.length > 0 ? locations[0] : null;

  return (
    <Box className="heatmap">
      {noData && (
        <Typography variant="h5" className="no-data-message">
          {t('noAgentData')}
        </Typography>
      )}
      <div className="map-box">
        <MapContainer center={center} zoom={13} style={{ height: '670px', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations.map((location, index) => (
            <Marker key={index} position={[location.latitude, location.longitude]} icon={customIcon}>
              <Popup>
                <div>
                  <strong>{t('time')}:</strong> {location.time} <br />
                  <strong>{t('latency')}:</strong> {location.value} ms
                </div>
              </Popup>
            </Marker>
          ))}
          <MapUpdater center={center} />
        </MapContainer>
      </div>
    </Box>
  );
};

export default HeatmapBox;
