import React, { useState } from 'react';
import './Projects.css';
import { Box, Typography, Paper, TextField, Button, Select, MenuItem } from '@mui/material';

const Projects = () => {
  const [campaignName, setCampaignName] = useState('');
  const [description, setDescription] = useState('');
  const [newEndpoint, setNewEndpoint] = useState('');
  const [endpoints, setEndpoints] = useState([]);
  const [checkInterval, setCheckInterval] = useState(60);
  const [timeout, setTimeout] = useState(5000);
  const [alertThreshold, setAlertThreshold] = useState(500);
  const [notificationMethod, setNotificationMethod] = useState('email');
  const [notificationTarget, setNotificationTarget] = useState('');

  const handleAddEndpoint = () => {
    if (newEndpoint.trim()) {
      setEndpoints([...endpoints, newEndpoint.trim()]);
      setNewEndpoint('');
    }
  };

  const handleRemoveEndpoint = (index) => {
    setEndpoints(endpoints.filter((_, i) => i !== index));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert('Latency monitoring campaign created successfully!');
    // Update the visualization
    const campaignName = document.getElementById('campaign-name').value;
    const endpointCount = document.getElementById('endpoint-list').children.length;
    const checkInterval = document.getElementById('check-interval').value;

    const preview = document.getElementById('campaign-preview');
    preview.innerHTML = `
      <h3>${campaignName}</h3>
      <p>Monitoring ${endpointCount} endpoints every ${checkInterval} seconds</p>
      <div style="width: 100%; height: 200px; background: linear-gradient(90deg, #e3f2fd 0%, #bbdefb 20%, #90caf9 50%, #64b5f6 80%, #42a5f5 100%); border-radius: 4px;"></div>
    `;
  };

  const menuProps = {
    PaperProps: {
      style: {
        backgroundColor: '#ffffff',
        color: 'black',
        borderRadius: '10px',
      },
    },
  };

  return (
    <Box className="projects">
      <Typography variant="h3" className="page-heading">Projects</Typography>
      <Paper className="settings-paper" style={{ padding: '20px' }}>
        <main>
          <section className="tool-section">
            <Typography variant="h4">Create Your Latency Monitoring Campaign</Typography>
            <form id="latency-campaign-form" onSubmit={handleSubmit}>
              <label htmlFor="campaign-name">Campaign Name:</label>
              <TextField
                id="campaign-name"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                required
                fullWidth
                margin="normal"
                placeholder="e.g., US Agents Latency Alerts"
                className="white-input"
              />
              <label htmlFor="description">Description:</label>
              <TextField
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={3}
                fullWidth
                margin="normal"
                placeholder="Describe the purpose and scope of this monitoring campaign"
                className="white-input"
              />
              <Typography variant="h5">Agents to Monitor</Typography>
              <label htmlFor="new-endpoint">Add Agents:</label>
              <TextField
                id="new-endpoint"
                value={newEndpoint}
                onChange={(e) => setNewEndpoint(e.target.value)}
                fullWidth
                margin="normal"
                placeholder="1"
                className="white-input"
              />
              <Button type="button" onClick={handleAddEndpoint} variant="contained" style={{ marginBottom: '10px' }}>Add Endpoint</Button>
              <ul id="endpoint-list" className="endpoint-list">
                {endpoints.map((endpoint, index) => (
                  <li key={index} className="endpoint-item">
                    {endpoint}
                    <Button type="button" className="remove-endpoint" onClick={() => handleRemoveEndpoint(index)}>remove</Button>
                  </li>
                ))}
              </ul>
              <Typography variant="h5">Monitoring Settings</Typography>
              <label htmlFor="check-interval">Check Interval (seconds):</label>
              <TextField
                id="check-interval"
                type="number"
                value={checkInterval}
                onChange={(e) => setCheckInterval(e.target.value)}
                inputProps={{ min: 5 }}
                fullWidth
                margin="normal"
                className="white-input"
              />
              <label htmlFor="timeout">Timeout (milliseconds):</label>
              <TextField
                id="timeout"
                type="number"
                value={timeout}
                onChange={(e) => setTimeout(e.target.value)}
                inputProps={{ min: 100 }}
                fullWidth
                margin="normal"
                className="white-input"
              />
              <label htmlFor="alert-threshold">Alert Threshold (milliseconds):</label>
              <TextField
                id="alert-threshold"
                type="number"
                value={alertThreshold}
                onChange={(e) => setAlertThreshold(e.target.value)}
                inputProps={{ min: 50 }}
                fullWidth
                margin="normal"
                className="white-input"
              />
              <Typography variant="h5">Notification Settings</Typography>
              <label  htmlFor="notification-method">Notification Method:</label>
              <Select
                id="notification-method"
                value={notificationMethod}
                onChange={(e) => setNotificationMethod(e.target.value)}
                fullWidth
                margin="normal"
                className="white-input"
                MenuProps={menuProps}
                style={{ color: 'white', fontSize: '18px', backgroundColor: '#323769', marginTop: '10px', marginBottom: '10px', border: '1px solid #ddd' }}
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="sms">SMS</MenuItem>
                <MenuItem value="slack">Slack</MenuItem>
                <MenuItem value="webhook">Webhook</MenuItem>
              </Select>
              <label htmlFor="notification-target">Notification Target:</label>
              <TextField
                id="notification-target"
                value={notificationTarget}
                onChange={(e) => setNotificationTarget(e.target.value)}
                fullWidth
                margin="normal"
                placeholder="Enter email, phone number, or webhook URL"
                className="white-input"
              />
              <Button type="submit" variant="contained" color="primary">Create Monitoring Campaign</Button>
            </form>
          </section>
          <section className="tool-section">
            <Typography variant="h4">Campaign Visualization</Typography>
            <p>Preview how your latency monitoring campaign will look:</p>
            <div className="visualization" id="campaign-preview">
              Campaign visualization will appear here after creation
            </div>
          </section>
        </main>
      </Paper>
    </Box>
  );
};

export default Projects;
