import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';

const generateEnhancedPDF = (reportTitle, agentId, chartData, chartInstance, timeFrame, refreshFrequency, openAIReport, appNetChartData, throughputChartData, lifbeChartData) => {
  const pdf = new jsPDF();
  const pageWidth = pdf.internal.pageSize.width;
  const pageHeight = pdf.internal.pageSize.height;
  const margin = 20;
  let yPosition = margin;

  // // Add logo to the top right of the first page
  const logoWidth = 128 / 4; // Reduce size by 1/4 to fit better on the page
  const logoHeight = 128 / 4;
  const logoX = pageWidth - margin - logoWidth;
  const logoY = margin - 15;
  pdf.addImage('favicon_logo.png', 'PNG', logoX, logoY, logoWidth, logoHeight);


  // Helper function to add a new page if needed
  const checkAndAddPage = (height) => {
    if (yPosition + height > pageHeight - margin) {
      pdf.addPage();
      yPosition = margin;
    }
  };

  // Helper function to add text with word wrap
  const addWrappedText = (text, fontSize, isBold = false) => {
    pdf.setFontSize(fontSize);
    pdf.setFont(undefined, isBold ? 'bold' : 'normal');
    const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
    checkAndAddPage(lines.length * fontSize * 0.5);
    pdf.text(lines, margin, yPosition);
    yPosition += lines.length * fontSize * 0.5 + 2;
  };

  // Helper function to add a chart
  const addChart = (chartInstance, title, height = 60) => {
    checkAndAddPage(height + 5);
    const chartImage = chartInstance.toBase64Image();
    pdf.addImage(chartImage, 'PNG', margin, yPosition, pageWidth - 2 * margin, height);
    yPosition += height + 5;
    addWrappedText(title, 10, true);
  };

  // Title and metadata
  addWrappedText(reportTitle || 'Latency Report', 18, true);
  addWrappedText(`Date: ${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`, 10);
  addWrappedText(`Agent ID: ${agentId} | Time Frame: ${timeFrame} | Refresh Frequency: ${refreshFrequency}`, 10);

  // Parse and add OpenAI report sections
  const fullReport = openAIReport.split('\nNetwork Performance Analysis Report')[0]; // Only process the first occurrence
  const sections = fullReport.split('\n## ').map(section => section.trim());

  sections.forEach((section, index) => {
    if (index === 0) return; // Skip the title section as we've already added it

    const [title, ...content] = section.split('\n');
    checkAndAddPage(30);
    addWrappedText(title, 14, true);

    // Add relevant chart based on the section title
    if (title.includes('Application and Network Latency')) {
      addChart(appNetChartData.chartInstance, 'Application and Network Latency Chart');
    } else if (title.includes('Protocol Latency')) {
      addChart(chartInstance, 'Protocol Latency Chart');
    } else if (title.includes('Throughput Performance')) {
      addChart(throughputChartData.chartInstance, 'Throughput Chart');
      addChart(lifbeChartData.chartInstance, 'LIFBE Throughput Chart');
    }

    // Add section content
    content.forEach(paragraph => {
      if (paragraph.trim().startsWith('-')) {
        // Handle bullet points
        addWrappedText('• ' + paragraph.trim().substring(1), 10);
      } else {
        addWrappedText(paragraph.trim(), 10);
      }
    });

    // Add a small gap between sections
    yPosition += 5;
  });

  return pdf;
};

export default generateEnhancedPDF;