import '../DetailedLatencyPage/DetailedLatency.css'
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Jitter from '../NetworkStatusSummaryPage/Jitter'
import JitterIcmpHistoryChart from './JitterIcmpHistoryChart'
import JitterTwampHistoryChart from './JitterTwampHistoryChart'
import { TimePeriodSelect, RefreshFrequencySelect } from '../TimeUtils';
import { AgentIdInput } from '../agentIdManager';
import { useAgent } from '../../../context/AgentProvider';
import { useTranslation } from 'react-i18next';

const JitterDisplay = () => {
  const [timePeriod, setTimePeriod] = useState('5min');
  const [refreshFrequency, setRefreshFrequency] = useState('5seconds');
  const { selectedAgent, getAgentId, updateAgentId } = useAgent();
  const [currentAgentId, setCurrentAgentId] = useState(getAgentId());
  // const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Initialize currentAgentId with the value from localStorage
    setCurrentAgentId(getAgentId());
  }, [selectedAgent]);

  const handleFetchData = (agentId) => {
    setCurrentAgentId(agentId);
    updateAgentId(agentId);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleRefreshFrequencyChange = (e) => {
    setRefreshFrequency(e.target.value);
  };

  return (
    <Box className="detailed-latency">
      <Typography variant="h3" className="page-heading">{t('jitterDisplay')}</Typography>
      <Box className="select-container">
        <TimePeriodSelect
          value={timePeriod}
          onChange={handleTimePeriodChange}
        />
        <RefreshFrequencySelect
          value={refreshFrequency}
          onChange={handleRefreshFrequencyChange}
        />
      </Box>

      <AgentIdInput onSubmit={handleFetchData} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <JitterIcmpHistoryChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={12}>
          <JitterTwampHistoryChart timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default JitterDisplay;



