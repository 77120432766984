import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './MobileApp.css';


const MobileApp = () => {
  const { t } = useTranslation();

  return (
    <Box className="mobile-app">
      <Typography variant="h3" className="page-heading">{t('mobileConfiguration')}</Typography>
      <img src="../mobile-config.png" />
    </Box>

  );
};

export default MobileApp;

