import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './PublicReflectors.css';

const PublicReflectors = () => {
  const { t } = useTranslation();

  const reflectors = [
    { name: "Montreal, Canada", hostname: "montreal-beacon.latence.ca" },
    { name: "North Central US, United States", hostname: "northcentral-beacon.latence.ca" },
    { name: "Paris, France", hostname: "paris-beacon.latence.ca" },
    { name: "Brazil", hostname: "brazil-beacon.latence.ca" },
    { name: "Quito, Equador", hostname: "quito-beacon.latence.ca" },
  ];

  return (
    <Box className="reflectors-container">
      <Typography variant="h3" className="page-heading">
        {t('publicReflectors')}
      </Typography>
      
      <div className="table-container">
        <table className="reflectors-table">
          <thead>
            <tr>
              <th>{t('refName')}</th>
              <th>{t('refHostname')}</th>
            </tr>
          </thead>
          <tbody>
            {reflectors.map((reflector, index) => (
              <tr key={index}>
                <td>{reflector.name}</td>
                <td>{reflector.hostname}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default PublicReflectors;