import OpenAI from "openai";
import i18n from 'i18next';

const API_KEY = process.env.REACT_APP_OPENAI_KEY;

const openai = new OpenAI({
  apiKey: API_KEY,
  dangerouslyAllowBrowser: true,
});

function truncateData(data, maxItems = 1) {
  if (Array.isArray(data)) {
    return data.slice(0, maxItems);
  } else if (typeof data === 'object' && data !== null) {
    const truncated = {};
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        truncated[key] = value.slice(0, maxItems);
      } else if (typeof value === 'object' && value !== null) {
        truncated[key] = truncateData(value, maxItems);
      } else {
        truncated[key] = value;
      }
    }
    return truncated;
  }
  return data;
}

function summarizeDataset(dataset) {
  if (!dataset || !dataset.data || dataset.data.length === 0) {
    return { label: dataset?.label || "Unknown", summary: "No data available" };
  }

  const validData = dataset.data.filter(point => point && typeof point.y === 'number' && !isNaN(point.y));

  if (validData.length === 0) {
    return { label: dataset.label, summary: "No valid data points" };
  }

  const latest = validData[validData.length - 1].y;
  const sum = validData.reduce((acc, point) => acc + point.y, 0);
  const avg = sum / validData.length;
  const max = Math.max(...validData.map(point => point.y));
  const min = Math.min(...validData.map(point => point.y));

  return {
    label: dataset.label,
    summary: `Latest: ${latest.toFixed(2)}, Avg: ${avg.toFixed(2)}, Max: ${max.toFixed(2)}, Min: ${min.toFixed(2)}`,
    samplePoints: validData.slice(-5).map(point => ({ x: point.x, y: point.y.toFixed(2) }))
  };
}

function prepareDateForOpenAI(data) {
  if (data && Array.isArray(data.datasets)) {
    return data.datasets.map(summarizeDataset);
  }
  return "No data available or unexpected format";
}

export async function generateReportWithOpenAI(reportData) {
  const { agentId, timeFrame, refreshFrequency, chartData, appNetChartData, throughputChartData, lifbeChartData } = reportData;

  const languageMap = {
    en: "English",
    fr: "French",
    es: "Spanish",
    swe: "Swedish",
    tc: "Traditional Chinesse",
    ja: "Japanese using a mix of Kanji, Hiragana and Katakana as appropriate"
  };

  const language = languageMap[i18n.language];

  const preparedChartData = prepareDateForOpenAI(chartData);
  const truncatedAppNetChartData = truncateData(appNetChartData);
  const preparedThroughputChartData = prepareDateForOpenAI(throughputChartData);
  const preparedLifbeChartData = prepareDateForOpenAI(lifbeChartData);

  // console.log("Truncated App/Net Chart Data:", JSON.stringify(truncatedAppNetChartData, null, 2));

  const prompt = `Generate a detailed network performance analysis report based on the following data:

Agent ID: ${agentId}
Time Frame: ${timeFrame}
Refresh Frequency: ${refreshFrequency}

Protocol Latency Data:
${JSON.stringify(preparedChartData, null, 2)}

Application and Network Latency Data:
${JSON.stringify(truncatedAppNetChartData, null, 2)}

Throughput Data:
${JSON.stringify(preparedThroughputChartData, null, 2)}

LIFBE Throughput Data:
${JSON.stringify(preparedLifbeChartData, null, 2)}

Please structure your report as follows, ensuring each section is detailed and data-driven:

# Network Performance Analysis Report

## Executive Summary
Provide a concise overview of the network performance, highlighting key findings and trends.

## Application and Network Latency Analysis
- Detailed analysis of Application and Network Latency
- Comparison between Application and Network Latency
- Trends and patterns observed
- Potential impact on overall network performance

## Protocol Latency Analysis
- Breakdown of latency for each protocol (HTTP, HTTPS, TCP, UDP, ICMP, TWAMP)
- Comparative analysis between different protocols
- Identification of protocols with highest and lowest latencies
- Potential reasons for latency differences

## Throughput Performance
- Analysis of TCP and UDP throughput (download and upload)
- LIFBE throughput analysis
- Comparison between different throughput metrics
- Identification of any bottlenecks or performance issues

## Key Observations
List at least 5 significant observations from the data, considering all metrics.

## Notable Performance Insights
Provide at least 3 insights derived from the throughput and latency data.

## Recommendations for Improving Network Performance
List 4-5 specific, actionable recommendations based on the analysis.

## Conclusion
Summarize the overall network performance and the potential impact of implementing the recommendations.

Please ensure that your analysis is data-driven, referencing specific values and trends from the provided datasets. Use markdown formatting for better readability.

Please responsed in ${language}`;

  try {
    const response = await openai.chat.completions.create({
      model: "ft:gpt-3.5-turbo-0125:latencetech::9OaPeakm:ckpt-step-1456",
      messages: [
        { role: "system", content: "You are an expert network performance analyst. Generate a comprehensive, detailed report based on the provided data, ensuring all sections are thoroughly addressed with specific references to the data." },
        { role: "user", content: prompt }
      ],
      temperature: 0.7,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      response_format: { type: "text" },
    });

    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error generating report with OpenAI:', error);
    return 'Error generating report. Please try again later.';
  }
}