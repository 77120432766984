import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const ProtocolTcp = ({ timePeriod, agentId, refreshFrequency }) => {
  const { auth: { customer_id } } = useAuth();
  const [tcpMs, setTcpMs] = useState(null);
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchMsFromInfluxDB = useCallback(async () => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) =>
          r._measurement == "tcp_result" and
          r._field == "delay" and
          r.agentID == "${agentId}" and
          r.customerID == "${customer_id}"
        )
        |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean)
        |> keep(columns: ["_time", "_value"])
        |> rename(columns: {"_value": "TCP"})
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\r\n');
        if (lines.length > 1) {
          const desiredRow = lines[2];
          const value = desiredRow.split(',')[4];
          const floatValue = parseFloat(value);
          return parseFloat(floatValue.toFixed(2));
        }
        return 0;
      } else {
        console.error(`Error response from InfluxDB: ${response.status} ${response.statusText}`);
        return 0;
      }
    } catch (error) {
      console.error(`Error fetching TCP data from InfluxDB:`, error);
      return 0;
    }
  }, [agentId, timePeriod, refreshFrequency]);

  const fetchDataAndUpdate = useCallback(async () => {
    const value = await fetchMsFromInfluxDB();
    setTcpMs(value);
  }, [fetchMsFromInfluxDB]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);

  const tcpColor = '#8ab8ff'; // The color used in the original line chart for TCP

  const gaugeData = [
    { name: 'Latency', value: tcpMs || 0 },
    { name: 'Remaining', value: Math.max(500 - (tcpMs || 0), 0) },
  ];

  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('averageTCP')}</Typography>
        <Box sx={{ height: 150, width: '100%' }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={gaugeData}
                cx="50%"
                cy="100%"
                startAngle={180}
                endAngle={0}
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                <Cell key="cell-0" fill={tcpColor} />
                <Cell key="cell-1" fill="#f3f3f3" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Typography variant="h4">{tcpMs !== null ? `${tcpMs} ms` : 'Loading...'}</Typography>
      </Paper>
    </Box>
  );
};

export default ProtocolTcp;