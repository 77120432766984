import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './theme.css'
import './i18n';
import { AgentProvider } from './context/AgentProvider';

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <React.StrictMode>
      <AuthProvider>
        <AgentProvider>
          <Router>
            <App />
            <ToastContainer />
          </Router>
        </AgentProvider>
      </AuthProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
