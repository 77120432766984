import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
//import axios from 'axios';
import 'chartjs-adapter-date-fns';
//import { Line } from 'react-chartjs-2';
//import { ResponsiveBar } from '@nivo/bar';
import './NetworkStatusSummary.css';
import Stability from './Stability'
import AgentMetadata from './Metadata'
import Volatility from './Volatility'
import PacketLoss from './PacketLoss'
import ConnectivityHealth from './ConnectivityHealth'
import LatencyHistoryChart from './LatencyHistoryChart'
import Jitter from './Jitter'
import NetworkBarChart from './NetworkBarChart'
import ApplicationBarChart from './ApplicationBarChart'
import ApplicationLatency from './ApplicationLatency'
import NetworkLatency from './NetworkLatency'
import TcpBandwidthDownload from '../BandwidthPage/TcpBandwidthDownload'
import TcpBandwidthUpload from '../BandwidthPage/TcpBandwidthUpload'
import ThroughputChart from '../BandwidthPage/ThroughputChart'
import { TimePeriodSelect, RefreshFrequencySelect } from '../TimeUtils';
import { AgentIdInput } from '../agentIdManager';
import { useAgent } from '../../../context/AgentProvider';

const NetworkStatusSummary = () => {
  const [timePeriod, setTimePeriod] = useState('5min');
  const [refreshFrequency, setRefreshFrequency] = useState('5seconds');
  const { selectedAgent, getAgentId, updateAgentId } = useAgent();
  const [currentAgentId, setCurrentAgentId] = useState(getAgentId());
  //const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Initialize currentAgentId with the value from localStorage
    setCurrentAgentId(getAgentId());
  }, [selectedAgent]);

  const handleFetchData = (agentId) => {
    setCurrentAgentId(agentId);
    updateAgentId(agentId);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleRefreshFrequencyChange = (e) => {
    setRefreshFrequency(e.target.value);
  };

  return (
    <Box className="network-status-summary">
      <Typography variant="h3" id="netstatsum" className="page-heading">{t('networkSummaryTitle')}</Typography>
      <Box className="select-container">
        <TimePeriodSelect
          value={timePeriod}
          onChange={handleTimePeriodChange}
        />
        <RefreshFrequencySelect
          value={refreshFrequency}
          onChange={handleRefreshFrequencyChange}
        />
      </Box>

      <AgentIdInput onSubmit={handleFetchData} />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <ConnectivityHealth timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={3}>
          <Stability timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <PacketLoss timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <Volatility timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <Jitter timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={4}>
          <ApplicationBarChart timePeriod={timePeriod} agentId={currentAgentId} refreshFrequency={refreshFrequency} />
        </Grid>
        <Grid item xs={4}>
          <NetworkBarChart timePeriod={timePeriod} agentId={currentAgentId} refreshFrequency={refreshFrequency} />
        </Grid>
        <Grid item xs={2}>
          <ApplicationLatency timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
          <br />
          <NetworkLatency timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={2}>
          <TcpBandwidthDownload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
          <br />
          <TcpBandwidthUpload timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={2}>
          <AgentMetadata timePeriod={timePeriod} refreshFrequency={refreshFrequency} agentId={currentAgentId} />
        </Grid>
        <Grid item xs={6}>
          <LatencyHistoryChart timePeriod={timePeriod} agentId={currentAgentId} refreshFrequency={refreshFrequency} />
        </Grid>
        <Grid item xs={4}>
          <ThroughputChart timePeriod={timePeriod} agentId={currentAgentId} refreshFrequency={refreshFrequency} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NetworkStatusSummary;
