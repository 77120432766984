import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const LicenseGate = () => {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    // const [userData, setUserData] = useState({});

    // const { auth } = useAuth();

    // const { role } = jwtDecode(auth.AuthToken);

    // console.log("role: " + role)



    // useEffect(() => {
    //     const AuthToken = localStorage.getItem('AuthToken');
    //     if (AuthToken) {
    //         const decodedData = JSON.parse(atob(AuthToken.split('.')[1]));
    //         setUserData(decodedData);
    //     } else { //unnessary protected route handles this
    //         // If no token is found, redirect to login
    //         navigate('/login');
    //     }
    // }, [navigate]);


    return (
        <div className='regiComponent'>
            <div>
                <h1>{t('unlicensed')}</h1>
                <p>{t('pleaseUpdate')}</p>
            </div>
        </div>
    );
};

export default LicenseGate;
