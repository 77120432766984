import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./licenseSelector.css";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

const LicenseSelector = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const enterprisePrice = process.env.REACT_APP_ENTERPRISEPRICE;
    const standardPrice = process.env.REACT_APP_STANDARDPRICE;
    const basicPrice = process.env.REACT_APP_BASICPRICE;

    const [openSections, setOpenSections] = useState(Array(11).fill(false));

    const toggleSection = (index) => {
        setOpenSections((prev) => {
            const newSections = [...prev];
            newSections[index] = !newSections[index];
            return newSections;
        });
    };

    const handleLicenseSelection = (licenseType) => {
        sessionStorage.setItem('SelectedLicenseType', licenseType);
        navigate('/registration');
    };

    return (
        <div className="outerbox">
            <div className='pricing-component'>
                <h1>{t('pricingTitle')}</h1>
                <p className="subtitle">{t('pricingSubtitle')}</p>

                <table className="pricing-table">
                    <thead>
                        <tr>
                            <th>{t('domain')}</th>
                            <th>{t('feature')}</th>
                            <th>{t('freePlan')}</th>
                            <th>{t('basicPlan')}</th>
                            <th>{t('standardPlan')}</th>
                            <th>{t('enterprisePlan')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>{t('price')}</td>
                            <td>{t('priceFree')}</td>
                            <td>{t('priceBasic', { count: basicPrice })}</td>
                            <td>{t('priceStandard', { count: standardPrice })}</td>
                            <td>{t('priceEnterprise', { count: enterprisePrice })}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t('duration')}</td>
                            <td>{t('durationFree')}</td>
                            <td>{t('durationMonthly')}</td>
                            <td>{t('durationMonthly')}</td>
                            <td>{t('durationMonthly')}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t('agents')}</td>
                            <td>5</td>
                            <td>5</td>
                            <td>25</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t('users')}</td>
                            <td>2</td>
                            <td>2</td>
                            <td>5</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <button onClick={() => handleLicenseSelection('Free')}>
                                    {t('selectFreeTrial')}
                                </button>
                            </td>
                            <td>
                                {/* <button onClick={() => handleLicenseSelection('Basic')}>
                                    {t('selectBasic')}
                                </button> */}
                                <button style={{
                                    backgroundColor: '#cccccc',
                                    color: '#666666',
                                    border: '1px solid #999999',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                    fontWeight: 'bold'
                                }}
                                >{t('comingSoon')}</button>
                            </td>
                            <td>
                                {/* <button onClick={() => handleLicenseSelection('Standard')}>
                                    {t('selectStandard')}
                                </button> */}
                                <button style={{
                                    backgroundColor: '#cccccc',
                                    color: '#666666',
                                    border: '1px solid #999999',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                    fontWeight: 'bold'
                                }}
                                >{t('comingSoon')}</button>
                            </td>
                            <td>
                                {/* <button onClick={() => handleLicenseSelection('Enterprise')}>
                                    {t('selectEnterprise')}
                                </button> */}
                                <button style={{
                                    backgroundColor: '#cccccc',
                                    color: '#666666',
                                    border: '1px solid #999999',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                    fontWeight: 'bold'
                                }}
                                >{t('comingSoon')}</button>

                            </td>
                        </tr>
                        {openSections[0] ? (
                            <>
                                <tr onClick={() => toggleSection(0)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('monitoring')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('endToEnd')}
                                            <span className="tooltiptext">{t('endToEndInfo')}</span>
                                        </span></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('qosAgent')}
                                            <span className="tooltiptext">{t('qosAgentInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('reflector')}
                                            <span className="tooltiptext">{t('reflectorInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('publicReflector')}
                                            <span className="tooltiptext">{t('publicReflectorInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('moblieApp')}
                                            <span className="tooltiptext">{t('moblieAppInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                </tr></>
                        ) : (<><tr onClick={() => toggleSection(0)} style={{ cursor: 'pointer' }}>
                            <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('monitoring')}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr></>)}

                        {openSections[1] ? (<>
                            <tr onClick={() => toggleSection(1)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('latenceMonitoring')}</td>
                                <td>
                                    <span className="tooltip">
                                        HTTP/S
                                        <span className="tooltiptext">{t('httpInfo')}</span>
                                    </span>
                                </td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        TCP
                                        <span className="tooltiptext">{t('tcpInfo')}</span>
                                    </span>
                                </td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        UDP
                                        <span className="tooltiptext">{t('udpInfo')}</span>
                                    </span>
                                </td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        TWAMP
                                        <span className="tooltiptext">{t('twampInfo')}</span>
                                    </span>
                                </td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        ICMP
                                        <span className="tooltiptext">{t('icmpInfo')}</span>
                                    </span>
                                </td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        {t('appLevel')}
                                        <span className="tooltiptext">{t('appLevelInfo')}</span>
                                    </span>
                                </td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        {t('netLevel')}
                                        <span className="tooltiptext">{t('netLevelInfo')}</span>
                                    </span>
                                </td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        {t('slaMonitoring')}
                                        <span className="tooltiptext">{t('slaMonitoringInfo')}</span>
                                    </span>
                                </td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        PROFINET
                                        <span className="tooltiptext">{t('profinetInfo')}</span>
                                    </span>
                                </td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        SCADA
                                        <span className="tooltiptext">{t('scadaInfo')}</span>
                                    </span>
                                </td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="checkmark"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="tooltip">
                                        MODBUS
                                        <span className="tooltiptext">{t('modbusInfo')}</span>
                                    </span>
                                </td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="crossmark"></td>
                                <td className="checkmark"></td>
                            </tr></>) : (<><tr onClick={() => toggleSection(1)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('latenceMonitoring')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr></>)}

                        {openSections[2] ? (
                            <>
                                <tr onClick={() => toggleSection(2)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('reliability')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('udpPacketRateLoss')}
                                            <span className="tooltiptext">{t('udpPacketRateLossInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('tcpPacketRateLoss')}
                                            <span className="tooltiptext">{t('tcpPacketRateLossInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(2)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('reliability')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                        {openSections[3] ? (
                            <>
                                <tr onClick={() => toggleSection(3)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('throughput')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('iperf3')}
                                            <span className="tooltiptext">{t('iperf3Info')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('lifbe')}
                                            <span className="tooltiptext">{t('lifbeInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(3)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('throughput')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                        {openSections[4] ? (
                            <>
                                <tr onClick={() => toggleSection(4)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('access')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('networkIdentification')}
                                            <span className="tooltiptext">{t('networkIdentificationInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('radioIndicators')}
                                            <span className="tooltiptext">{t('radioIndicatorsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(4)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('access')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                        {openSections[5] ? (
                            <>
                                <tr onClick={() => toggleSection(5)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('analysisAndDashboards')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('networkSummary')}
                                            <span className="tooltiptext">{t('networkSummaryInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('compareAgents')}
                                            <span className="tooltiptext">{t('compareAgentsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('detailedLatency')}
                                            <span className="tooltiptext">{t('detailedLatencyInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('detailedJitter')}
                                            <span className="tooltiptext">{t('detailedJitterInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('throughputDashboard')}
                                            <span className="tooltiptext">{t('throughputDashboardInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('packetLossDashboard')}
                                            <span className="tooltiptext">{t('packetLossDashboardInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('forecasting')}
                                            <span className="tooltiptext">{t('forecastingInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('dataReports')}
                                            <span className="tooltiptext">{t('dataReportsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('trafficShaper')}
                                            <span className="tooltiptext">{t('trafficShaperInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(5)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('analysisAndDashboards')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                        {openSections[6] ? (
                            <>
                                <tr onClick={() => toggleSection(6)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('dataManagement')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('historyRetention')}
                                            <span className="tooltiptext">{t('historyRetentionInfo')}</span>
                                        </span>
                                    </td>
                                    <td>30</td>
                                    <td>90</td>
                                    <td>90</td>
                                    <td>90+</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('encryptedCommunications')}
                                            <span className="tooltiptext">{t('encryptedCommunicationsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(6)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('dataManagement')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                        {openSections[7] ? (
                            <>
                                <tr onClick={() => toggleSection(7)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('configurationManagement')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('configureAgents')}
                                            <span className="tooltiptext">{t('configureAgentsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('configureReflectors')}
                                            <span className="tooltiptext">{t('configureReflectorsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(7)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('configurationManagement')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                        {openSections[8] ? (
                            <>
                                <tr onClick={() => toggleSection(8)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('security')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('noPacketCapture')}
                                            <span className="tooltiptext">{t('noPacketCaptureInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('dataEncryptedAtRest')}
                                            <span className="tooltiptext">{t('dataEncryptedAtRestInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('userRolesPermissions')}
                                            <span className="tooltiptext">{t('userRolesPermissionsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('auditLogs')}
                                            <span className="tooltiptext">{t('auditLogsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('automaticUpdates')}
                                            <span className="tooltiptext">{t('automaticUpdatesInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(8)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('security')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                        {openSections[9] ? (
                            <>
                                <tr onClick={() => toggleSection(9)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('supportServices')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('onlineDocumentation')}
                                            <span className="tooltiptext">{t('onlineDocumentationInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('videoTraining')}
                                            <span className="tooltiptext">{t('videoTrainingInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('liveChat')}
                                            <span className="tooltiptext">{t('liveChatInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('emailSupport')}
                                            <span className="tooltiptext">{t('emailSupportInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('electronicPayments')}
                                            <span className="tooltiptext">{t('electronicPaymentsInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('onboardingSession')}
                                            <span className="tooltiptext">{t('onboardingSessionInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('dedicatedSupport')}
                                            <span className="tooltiptext">{t('dedicatedSupportInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(9)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('supportServices')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                        {openSections[11] ? (
                            <>
                                <tr onClick={() => toggleSection(11)} style={{ cursor: 'pointer' }}>
                                    <td style={{ textAlign: 'left' }}><FaMinusCircle />  {t('multilanguageSupport')}</td>
                                    <td>
                                        <span className="tooltip">
                                            {t('english')}
                                            <span className="tooltiptext">{t('englishInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('french')}
                                            <span className="tooltiptext">{t('frenchInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('spanish')}
                                            <span className="tooltiptext">{t('spanishInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('swedish')}
                                            <span className="tooltiptext">{t('swedishInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('traditionalChinese')}
                                            <span className="tooltiptext">{t('traditionalChineseInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('japanese')}
                                            <span className="tooltiptext">{t('japaneseInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span className="tooltip">
                                            {t('otherLanguage')}
                                            <span className="tooltiptext">{t('otherLanguageInfo')}</span>
                                        </span>
                                    </td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="crossmark"></td>
                                    <td className="checkmark"></td>
                                </tr>
                            </>
                        ) : (
                            <tr onClick={() => toggleSection(11)} style={{ cursor: 'pointer' }}>
                                <td style={{ textAlign: 'left' }}><FaPlusCircle />  {t('multilanguageSupport')}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    );
};

export default LicenseSelector;