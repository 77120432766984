import '../DetailedLatencyPage/DetailedLatency.css'
import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const JitterTwampHistoryChart = ({ timePeriod, refreshFrequency, agentId }) => {
  const { auth: { customer_id } } = useAuth();
  const [jitterHistory, setJitterHistory] = useState([]);
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchJitterData = async () => {
    const query = `
      import "influxdata/influxdb/schema"
      import "math"
      import "date"

      window_length = ${parseTimeRange(timePeriod)}
      window_shift = ${parseTimeRange(refreshFrequency)}

      data = from(bucket: "LatenceTech")
        |> range(start: date.sub(d: window_length, from: -${parseTimeRange(timePeriod)}))
        |> filter(fn: (r) => r["customerID"] == "${customer_id}" and r["agentID"] == "${agentId}")
        |> filter(fn: (r) => 

            r["_measurement"] == "tcp_result"

          )
        |> filter(fn: (r) => r["_field"] == "delay")
        |> drop(columns: ["_measurement"])

      windows = data
        |> window(every: window_shift, period: window_length)

      mean_abs_diff = windows
        |> difference()
        |> map(fn: (r) => ({ r with _value: math.abs(x: r._value) }))
        |> mean()
        |> set(key: "_field", value: "absdiff")
        |> drop(columns: ["_time"])
        |> duplicate(column: "_stop", as: "_time")
        |> keep(columns: ["_time", "_field", "_value"])

      mean_data = windows
        |> mean()
        |> drop(columns: ["_time"])
        |> duplicate(column: "_stop", as: "_time")
        |> keep(columns: ["_time", "_field", "_value"])

      result = join(tables: {t1: mean_abs_diff, t2: mean_data}, on: ["_time"])
        |> map(fn: (r) => ({ r with _value: (r._value_t1 / r._value_t2) * 100.0 }))
        |> keep(columns: ["_time", "_field", "_value"])
        |> range(start: -${parseTimeRange(timePeriod)})
        
      n_records = (result
        |> count()
        |> findRecord(fn: (key) => true, idx: 0))._value

      result |> limit(n: n_records)
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1);
        const values = lines
          .filter(line => line.includes('_result'))
          .map(line => {
            const parts = line.split(',');
            // console.log("PARTS", parts)
            return {
              time: new Date(parts[5].replace(/"/g, '')),
              value: parseFloat(parts[6].replace(/"/g, ''))
            };
          })
          .filter(entry => !isNaN(entry.value));

        if (values.length > 0) {
          setJitterHistory(values);
        }
      } else {
        console.error(`Error fetching jitter data from InfluxDB: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching jitter data from InfluxDB:`, error);
    }
  };

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchJitterData();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchJitterData();
    }, refreshInterval);

    return () => clearInterval(intervalIdRef.current);
  }, [agentId, refreshFrequency, timePeriod]);


  const jitterChartData = {
    labels: jitterHistory.map(entry => entry.time),
    datasets: [
      {
        label: 'TWAMP Jitter',
        data: jitterHistory.map(entry => entry.value),
        borderColor: 'rgb(250, 222, 42)',
        backgroundColor: 'rgba(250, 222, 42, 0.2)',
        pointRadius: 1,
        fill: true,
        tension: 0.4,
        cubicInterpolationMode: 'monotone'
      }
    ]
  };


  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            second: 'HH:mm:ss',
            minute: 'HH:mm'
          }
        },
        ticks: {
          color: '#E9F4F4',
        },
        grid: {
          color: 'rgba(233, 244, 244, 0.2)',
        },
      },
      y: {
        ticks: {
          color: '#E9F4F4',
        },
        grid: {
          color: 'rgba(233, 244, 244, 0.2)',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#E9F4F4',
        },
      },
      tooltip: {
        bodyColor: '#E9F4F4',
        titleColor: '#E9F4F4',
      },
    },
  };

  return (
    <Box className="detailed-latency">
      <Paper className="network-status-summary paper latency-history">
        <Typography variant="h5">{t('jitterTwampHistory')} (%)</Typography>
        <div className="chart-container">
          <Line data={jitterChartData} options={chartOptions} />
        </div>
      </Paper>
    </Box>
  );
};

export default JitterTwampHistoryChart;