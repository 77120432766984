import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './NetworkStatusSummary.css';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';

const ApplicationLatency = ({ timePeriod, refreshFrequency, agentId }) => {
  const [applicationLatency, setApplicationLatency] = useState(null);
  const intervalIdRef = useRef(null);
  // const [debugInfo, setDebugInfo] = useState('');
  const { t } = useTranslation();
  const { auth } = useAuth();
  const customer_id = auth.customer_id;

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchApplicationLatency = async () => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "http_result" or r["_measurement"] == "https_result" or r["_measurement"] == "tcp_result" or r["_measurement"] == "udp_result")
        |> filter(fn: (r) => r["_field"] == "delay")
        |> keep(columns: ["_measurement", "_time", "_value"])
        |> group(columns: ["_measurement"])
        |> last()
        |> group()
        |> mean()
        |> rename(columns: {_value: "Application"})
        |> keep(columns: ["_time", "Application"])
        |> yield(name: "Application")
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        const values = lines.map(line => {
          const parts = line.split(',');
          const value = parseFloat(parts[3]);
          const formattedValue = parseFloat(value.toFixed(2));
          setApplicationLatency(formattedValue)
          return formattedValue;
        });
        return values;
      } else {
        console.error(`Error fetching application latency data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching application latency data from InfluxDB:`, error);
      return [];
    }
  };


  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchApplicationLatency();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchApplicationLatency();
    }, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [agentId, refreshFrequency, timePeriod]);


  return (
    <Box className="network-status-summary">
      <Paper className="network-status-summary paper">
        <Typography variant="h5">{t('applicationLatency')}</Typography>
        <Typography variant="h4">{applicationLatency} ms</Typography>
      </Paper>
    </Box>
  );
};

export default ApplicationLatency;