// src/TerminalID.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TerminalID.css';
import { Typography, Grid, Paper, Button } from '@mui/material';

function TerminalID() {
  const navigate = useNavigate();

  const handleConnect = (ip) => {
    navigate('/terminal', { state: { ipAddress: ip } });
  };

  const agents = [
    {
      name: 'Agent US EAST',
      id: '1',
      reflector: 'montreal-beacon.latence.ca',
      agent_ip: '57.151.89.136'
    },
    {
      name: 'Germany WEST',
      id: '2',
      reflector: 'paris-beacon.latence.ca',
      agent_ip: '51.116.104.58'
    },
    {
      name: 'South Central US',
      id: '3',
      reflector: 'northcentral-beacon.latence.ca',
      agent_ip: '13.85.86.191'
    },
  ];

  return (
    <div className='terminalId'>
      <Typography variant="h3" className="page-heading">Manage Agents</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className="header-paper" elevation={3}>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="h5">NAME</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5">AGENT ID</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5">REFLECTOR</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {agents.map((agent, index) => (
          <Grid item xs={12} key={index}>
            <Paper className="agent-paper" elevation={3}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography variant="h6">{agent.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle">{agent.id}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle">{agent.reflector}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => handleConnect(agent.agent_ip)}
                  >
                    <img src="/shell_symbol.png" width="30" height="30"/>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default TerminalID;
