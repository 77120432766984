import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './Team.css';
import { Grid, Box, Typography, Paper, Select, MenuItem, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';

const Team = () => {
  const [profiles, setProfiles] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [newProfile, setNewProfile] = useState({
    fname: '',
    lname: '',
    email: '',
    position: 'viewer',
    lastConnected: new Date().toISOString().split('T')[0], // current date
  });
  const { auth } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [newRole, setNewRole] = useState('');
  const { t } = useTranslation();

  // Decode token and set role
  useEffect(() => {
    if (auth?.AuthToken) {
      try {
        const decodedToken = jwtDecode(auth?.AuthToken);
        setUserRole(decodedToken.role);
      } catch (error) {
        console.error('Error decoding token:', error);
        setUserRole(null);
      }
    }
  }, [auth?.AuthToken]);

  // Fetch profiles from the backend
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/team`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth?.AuthToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch profiles');
        }
        const data = await response.json();
        setProfiles(data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    fetchProfiles();
  }, [auth?.AuthToken]);

  const handlePositionChange = (index, event) => {
    setSelectedIndex(index);
    setNewRole(event.target.value);
    setConfirmDialogOpen(true);
  };

  const handleConfirmChange = async () => {
    try {
      const profileToUpdate = profiles[selectedIndex];
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/team/position`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: profileToUpdate.email, position: newRole }),
      });

      if (!response.ok) {
        throw new Error('Failed to update position');
      }

      const updatedProfile = await response.json();

      const newProfiles = [...profiles];
      newProfiles[selectedIndex] = updatedProfile;
      setProfiles(newProfiles);

    } catch (error) {
      console.error('Error updating position:', error);
    }

    setConfirmDialogOpen(false);
  };

  const handleCancelChange = () => {
    setConfirmDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogChange = (event) => {
    const { name, value } = event.target;
    setNewProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddProfile = async () => {
    // Generate a unique token for password creation
    const token = Math.random().toString(36).substr(2, 15);

    const body = { newProfile, token }
    try {
      const profileResponse = await fetch(`${process.env.REACT_APP_BACKEND}/team`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (!profileResponse.ok) {
        console.log("profile response not ok")
        const errorData = await profileResponse.json();
        if (errorData.error === "Email already in use") {
          handleDialogClose();
          toast.error(`${t('emailInUse')}`);
        } else if (errorData.error === 'Max Users reached')
          toast.error(`${t('maxUsers')}`)
        throw new Error('Failed to add profile');
      } else {
        console.log("profile response ok");
        const addedProfile = await profileResponse.json();
        setProfiles((prevState) => [...prevState, addedProfile]);
        setNewProfile({
          fname: '',
          lname: '',
          email: '',
          position: 'viewer',
          lastConnected: new Date().toISOString().split('T')[0],
        });
        handleDialogClose();

        console.log('Sending email request...');
        const mailResponse = await axios.post(`${process.env.REACT_APP_BACKEND}/user/send-email`, {
          to: newProfile.email,
          subject: 'Welcome to the Team!',
          text: `Hello ${newProfile.fname},\n\nYou have been added to the team with the role of ${newProfile.position}. Welcome aboard!\n\nTo set up your account, please click on the following link to create your password:\n\n${process.env.REACT_APP_FRONTEND}/new-password/${token}\n\nBest regards,\nYour Team`,
          token: token,
          userId: newProfile.email // Using email as a unique identifier
        });
        if (!mailResponse.ok) {
          console.log("mail response not ok");
          const errorData = await mailResponse.json();
          if (mailResponse.status === 500) {
            toast.error(t('mailError'));
          }
        }
        console.log('Email sent successfully:', mailResponse.data);
      }
    } catch (error) {
      console.error('Error adding profile:', error);
    }
  };

  const handleDeleteProfile = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/team/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({ email })
      });
      if (!response.ok) {
        throw new Error('Failed to delete profile');
      }
      const newProfiles = profiles.filter(profile => profile.email !== email);
      setProfiles(newProfiles);
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  };

  const menuProps = {
    PaperProps: {
      style: {
        backgroundColor: '#ffffff',
        color: 'black',
        borderRadius: '10px',
      },
    },
  };

  return (
    <Box className="team">
      <Typography variant="h3" className="page-heading">{t('teamManagement')}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className="header-paper" elevation={3}>
            <Grid container>
              <Grid item xs={1}>
                <Typography variant="h7">{t('firstName')}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h7">{t('lastName')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h7">{t('emailAddress')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h7">{t('role')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h7">{t('lastConnection')}</Typography>
              </Grid>
              {userRole === 'admin' && (
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                  <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                    {t('addUser')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        {profiles.map((profile) => (
          <Grid item xs={12} key={profile._id}>
            <Paper className="profile-paper" elevation={3}>
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <Typography variant="subtitle1">{profile.fname}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle1">{profile.lname}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">{profile.email}</Typography>
                </Grid>
                <Grid item xs={3}>
                  {userRole === 'admin' && profile.position !== 'admin' ? (
                    <Select
                      value={profile.position}
                      onChange={(event) =>
                        handlePositionChange(profiles.findIndex((p) => p.email === profile.email), event)
                      }
                      displayEmpty
                      fullWidth
                      MenuProps={menuProps}
                      style={{ color: 'white', borderRadius: '10px', fontSize: '18px' }}
                    >
                      <MenuItem value="admin">{t('administrator')}</MenuItem>
                      <MenuItem value="editor">{t('editor')}</MenuItem>
                      <MenuItem value="viewer">{t('viewer')}</MenuItem>
                    </Select>
                  ) : (
                    <Typography variant="subtitle1">
                      {profile.position === 'admin' && t('administrator')}
                      {profile.position === 'editor' && t('editor')}
                      {profile.position === 'viewer' && t('viewer')}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">{profile.lastConnected}</Typography>
                </Grid>
                {userRole === 'admin' && profile.position !== 'admin' && (
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <Button
                      className="team-delete"
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteProfile(profile.email)}
                    >
                      {t('delete')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle className="add-user-field">{t('addUser')}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label={t('employeeFirstName')}
            name="fname"
            value={newProfile.fname}
            onChange={handleDialogChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label={t('employeeLastName')}
            name="lname"
            value={newProfile.lname}
            onChange={handleDialogChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label={t('employeeEmail')}
            name="email"
            value={newProfile.email}
            onChange={handleDialogChange}
            fullWidth
          />
          <Select
            margin="dense"
            label={t('position')}
            name="position"
            value={newProfile.position}
            onChange={handleDialogChange}
            fullWidth
            MenuProps={menuProps}
          >
            <MenuItem value="admin">{t('administrator')}</MenuItem>
            <MenuItem value="editor">{t('editor')}</MenuItem>
            <MenuItem value="viewer">{t('viewer')}</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button className="team-delete" onClick={handleDialogClose} color="primary">
            {t('cancel')}
          </Button>
          <Button className="team-add" onClick={handleAddProfile} color="primary">
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmDialogOpen} onClose={handleCancelChange}>
        <DialogTitle>{t('confirmRoleChange')}</DialogTitle>
        <DialogContent>
          <Typography>{t('confirm')} {newRole}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChange} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleConfirmChange} color="primary">
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Team;
