import './DetailedLatency.css'
import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import the date adapter
import { Line } from 'react-chartjs-2';
import '../NetworkStatusSummaryPage/NetworkStatusSummary.css';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);


const TwampLatencyChart = ({ timePeriod, refreshFrequency, agentId }) => {
  const { auth: { customer_id } } = useAuth();
  const { t } = useTranslation();

  const [twampLatencyHistory, setTwampLatencyHistory] = useState([]);
  const intervalIdRef = useRef(null);

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchTwampLatency = async () => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${parseTimeRange(timePeriod)})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "twamp_result")
        |> filter(fn: (r) => r["_field"] == "delay")
        |> keep(columns: ["_time", "_value"])
        |> aggregateWindow(every: ${parseTimeRange(refreshFrequency)}, fn: mean, createEmpty: false)
        |> rename(columns: {_value: "TWAMP Latency"})
        |> keep(columns: ["_time", "TWAMP Latency"])
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        const values = lines.map(line => {
          const parts = line.split(',');
          return { time: new Date(parts[3]), value: parseFloat(parts[4]) };
        });
        setTwampLatencyHistory(values);
      } else {
        console.error(`Error fetching Twamp latency data from InfluxDB: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching Twamp latency data from InfluxDB:`, error);
    }
  };

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    fetchTwampLatency();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(() => {
      fetchTwampLatency();
    }, refreshInterval);

    return () => clearInterval(intervalIdRef.current);
  }, [agentId, refreshFrequency, timePeriod]);



  const twampChartData = {
    labels: twampLatencyHistory.map(entry => entry.time),
    datasets: [
      {
        label: 'TWAMP Latency',
        data: twampLatencyHistory.map(entry => entry.value),
        borderColor: 'rgb(250, 222, 42)',
        backgroundColor: 'rgba(250, 222, 42 , 0.2)',
        pointRadius: 1,
        fill: true,
        tension: 0.4,
        cubicInterpolationMode: 'monotone'
      }
    ]
  };


  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            second: 'HH:mm:ss',
            minute: 'HH:mm'
          }
        },
        ticks: {
          color: '#E9F4F4',
        },
        grid: {
          color: 'rgba(233, 244, 244, 0.2)',
        },
      },
      y: {
        ticks: {
          color: '#E9F4F4',
        },
        grid: {
          color: 'rgba(233, 244, 244, 0.2)',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#E9F4F4',
        },
      },
      tooltip: {
        bodyColor: '#E9F4F4',
        titleColor: '#E9F4F4',
      },
    },
  };


  return (
    <Box className="detailed-latency">
      <Paper className="network-status-summary paper latency-history">
        <Typography variant="h5">{t('twampHistory')} (ms)</Typography>
        <div className="chart-container">
          <Line data={twampChartData} options={chartOptions} />
        </div>
      </Paper>
    </Box>
  );
};

export default TwampLatencyChart;