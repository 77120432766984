import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseTimeRange, getRefreshInterval } from '../TimeUtils';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import './LatencyForecast.css';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const ProjectedLatencyChart = ({ timePeriod, agentId, refreshFrequency }) => {
  const { auth: { customer_id } } = useAuth();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  // const [chartOptions, setChartOptions] = useState({});
  const intervalIdRef = useRef(null);
  const { t } = useTranslation();

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  // const fetchChartData = useCallback(async () => {
  //   const data = `
  //     import "influxdata/influxdb/schema"
  //     import "math"
  //     import "date"

  //     window_length=5m
  //     window_shift=5s
  //     forecast_length=1m

  //     data = from(bucket: "LatenceTech")
  //       |> range(start: date.sub(d: window_length, from: -${parseTimeRange(timePeriod)}), stop: now())
  //       |> filter(fn: (r) => r["_field"] == "delay")
  //       |> filter(fn: (r) => r["_measurement"] == "icmp_result"
  //          or r["_measurement"] == "udp_result"
  //          or r["_measurement"] == "tcp_result"
  //          or r["_measurement"] == "twamp_result"
  //          or r["_measurement"] == "http_result"
  //          or r["_measurement"] == "https_result")
  //       |> filter(fn: (r) => r["agentID"] == "${agentId}" and
  //         r.customerID == "${customer_id}")
  //       |> drop(columns: ["_measurement"])

  //     windows = data
  //       |> window(every: window_shift, period: window_length)

  //     observed = data
  //       |> range(start: -${parseTimeRange(timePeriod)}, stop: date.add(d: 1m, to: date.add(d: 1m, to: now())))
  //       |> aggregateWindow(every: window_shift, fn: mean, createEmpty: false)
  //       |> keep(columns: ["_time", "_value"])
  //       |> rename(columns: {_value: "Observed latency"})
  //       |> yield(name: "Observed latency")
       
  //     mean = windows
  //       |> mean(column: "_value")
  //       |> duplicate(column: "_stop", as: "_time")
  //       |> set(key: "_field", value: "mean")
  //       |> keep(columns: ["_time", "_field", "_value"])
  //       |> window(every: inf)
  //       |> timeShift(duration: forecast_length)
  //       |> range(start: -${parseTimeRange(timePeriod)}, stop: date.add(d: forecast_length, to: date.add(d: 1m, to: now())))

  //     stddev = windows
  //       |> stddev(column: "_value", mode: "population")
  //       |> duplicate(column: "_stop", as: "_time")
  //       |> set(key: "_field", value: "stddev")
  //       |> keep(columns: ["_time", "_field", "_value"])
  //       |> window(every: inf)
  //       |> timeShift(duration: forecast_length)
  //       |> range(start: -${parseTimeRange(timePeriod)}, stop: date.add(d: forecast_length, to: date.add(d: 1m, to: now())))

  //     max = union(tables:[mean, stddev])
  //       |> schema.fieldsAsCols()
  //       |> map(fn: (r) => ({ r with _value: (r.mean + r.stddev)}))
  //       |> set(key: "_field", value: "max")
  //       |> keep(columns: ["_time", "_field", "_value"])
  //       |> rename(columns: {_value: "Forecasted maximum latency"})
  //       |> yield(name: "Forecasted maximum latency")

  //     min = union(tables:[mean, stddev])
  //       |> schema.fieldsAsCols()
  //       |> map(fn: (r) => ({ r with _value: math.mMax(x: 0., y: r.mean - r.stddev)}))
  //       |> set(key: "_field", value: "min")
  //       |> keep(columns: ["_time", "_field", "_value"])
  //       |> rename(columns: {_value: "Forecasted minimum latency"})
  //       |> yield(name: "Forecasted minimum latency")

  //     meanout = mean
  //       |> range(start: -${parseTimeRange(timePeriod)}, stop: date.add(d: forecast_length, to: date.add(d: 1m, to: now())))
  //       |> keep(columns: ["_time", "_value"])
  //       |> rename(columns: {_value: "Forecasted mean latency"})
  //       |> yield(name: "Forecasted mean latency")`;

  //   try {
  //     const response = await fetch(API_URL, {
  //       method: "POST",
  //       headers: HEADERS,
  //       body: data
  //     });
  //     if (response.ok) {
  //       const csvData = await response.text();
  //       const lines = csvData.trim().split('\n').slice(1); // Skip header line
  //       const observedLatency = [];
  //       const forecastedMaxLatency = [];
  //       const forecastedMinLatency = [];
  //       const forecastedMeanLatency = [];

  //       lines.forEach(line => {
  //         const parts = line.split(',');
  //         const value = parseFloat(parts[4]);
  //         const valueMean = parseFloat(parts[3]);

  //         if (line.includes('Observed latency')) {
  //           observedLatency.push({ time: parts[3], value });
  //         } else if (line.includes('Forecasted maximum latency')) {
  //           forecastedMaxLatency.push({ time: parts[3], value });
  //         } else if (line.includes('Forecasted minimum latency')) {
  //           forecastedMinLatency.push({ time: parts[3], value });
  //         } else if (line.includes('Forecasted mean latency')) {
  //           forecastedMeanLatency.push({ time: parts[4], valueMean });
  //         }
  //       });

  //       setChartData({
  //         labels: observedLatency.map(entry => entry.time),
  //         datasets: [
  //           {
  //             label: 'Observed latency',
  //             data: observedLatency.map(entry => entry.value),
  //             borderColor: 'rgb(31, 119, 180)',
  //             backgroundColor: 'rgba(31, 119, 180, 0.2)',
  //             fill: false,
  //             tension: 0.4,
  //             cubicInterpolationMode: 'monotone'
  //           },
  //           {
  //             label: 'Forecasted latency',
  //             data: forecastedMeanLatency.map(entry => entry.valueMean),
  //             borderColor: 'rgb(250, 222, 42)',
  //             backgroundColor: 'rgba(250, 222, 42 , 0.2)',
  //             fill: false,
  //             tension: 0.4,
  //             cubicInterpolationMode: 'monotone'
  //           },
  //         ]
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error fetching chart data:', error);
  //   }
  // }, [agentId, timePeriod]);

  const fetchChartData = useCallback(async () => {
    const data = `
      import "influxdata/influxdb/schema"
      import "math"
      import "date"

      window_length=5m
      window_shift=5s
      forecast_length=30s

      data = from(bucket: "LatenceTech")
        |> range(start: date.sub(d: window_length, from: -${parseTimeRange(timePeriod)}), stop: now())
        |> filter(fn: (r) => r["_field"] == "delay")
        |> filter(fn: (r) => r["_measurement"] == "icmp_result"
           or r["_measurement"] == "udp_result"
           or r["_measurement"] == "tcp_result"
           or r["_measurement"] == "twamp_result"
           or r["_measurement"] == "http_result"
           or r["_measurement"] == "https_result")
        |> filter(fn: (r) => r["agentID"] == "${agentId}" and
          r.customerID == "${customer_id}")
        |> drop(columns: ["_measurement"])

      windows = data
        |> window(every: window_shift, period: window_length)

      observed = data
        |> range(start: -${parseTimeRange(timePeriod)}, stop: now())
        |> aggregateWindow(every: window_shift, fn: mean, createEmpty: false)
        |> keep(columns: ["_time", "_value"])
        |> rename(columns: {_value: "Observed latency"})
        |> yield(name: "Observed latency")
       
      mean = windows
        |> mean(column: "_value")
        |> duplicate(column: "_stop", as: "_time")
        |> set(key: "_field", value: "mean")
        |> keep(columns: ["_time", "_field", "_value"])
        |> window(every: inf)

      meanout = mean
        |> range(start: date.sub(d: 30s, from: now()), stop: now())
        |> keep(columns: ["_time", "_value"])
        |> map(fn: (r) => ({ 
            r with 
            _time: date.add(d: 30s, to: r._time),
            _value: r._value
        }))
        |> rename(columns: {_value: "Forecasted latency(ms)"})
        |> yield(name: "Forecasted mean latency")`;

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: HEADERS,
        body: data
      });
      
      if (response.ok) {
        const csvData = await response.text();
        
        const lines = csvData.trim().split('\n').slice(1);
        const observedLatency = [];
        const forecastedMeanLatency = [];
        const currentTime = new Date().getTime();

        lines.forEach(line => {
          const parts = line.split(',');

          if (line.includes('Observed latency')) {
            observedLatency.push({ 
              time: parts[3], 
              value: parseFloat(parts[4])
            });
          } else if (line.includes('Forecasted mean latency')) {
            // Parse the timestamp and add 30 seconds
            const timestamp = new Date(parts[3]);
            timestamp.setSeconds(timestamp.getSeconds());
            
            forecastedMeanLatency.push({ 
              time: timestamp.toISOString(), 
              value: parseFloat(parts[4]) 
            });
          }
        });

        setChartData({
          labels: [...observedLatency.map(entry => entry.time), 
                  ...forecastedMeanLatency.map(entry => entry.time)],
          datasets: [
            {
              label: 'Observed latency',
              data: observedLatency.map(entry => entry.value),
              borderColor: 'rgb(31, 119, 180)',
              backgroundColor: 'rgba(31, 119, 180, 0.2)',
              fill: false,
              tension: 0.4,
              cubicInterpolationMode: 'monotone'
            },
            {
              label: 'Forecasted latency',
              data: forecastedMeanLatency.map(entry => ({
                x: new Date(entry.time).getTime(),
                y: entry.value
              })),
              borderColor: 'rgb(250, 222, 42)',
              backgroundColor: 'rgba(250, 222, 42, 0.2)',
              borderDash: [5, 5],
              pointRadius: 3,
              pointBackgroundColor: 'rgb(250, 222, 42)',
              fill: false,
              tension: 0
            },
          ]
        });
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  }, [agentId, timePeriod, customer_id]);

  const calculateStartTime = useCallback((timePeriod) => {
    const now = new Date();
    const periodMapping = {
      '5min': 5 * 60 * 1000,
      '15min': 15 * 60 * 1000,
      '30min': 30 * 60 * 1000,
      '1hour': 60 * 60 * 1000,
      '3hours': 3 * 60 * 60 * 1000,
      '6hours': 6 * 60 * 60 * 1000,
      '12hours': 12 * 60 * 60 * 1000,
      '24hours': 24 * 60 * 60 * 1000,
    };
    const offset = periodMapping[timePeriod] || 5 * 60 * 1000;
    return new Date(now.getTime() - offset + 60 * 1000); // Subtract time period and add 1 minute
  }, []);

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            second: 'HH:mm:ss',
            minute: 'HH:mm'
          }
        },
        ticks: {
          color: '#E9F4F4',
        },
        grid: {
          color: 'rgba(233, 244, 244, 0.2)',
        },
        min: calculateStartTime(timePeriod),
        max: new Date(new Date().getTime() + 30000) // Extend 35 seconds to show forecast
      },
      y: {
        ticks: {
          color: '#E9F4F4',
        },
        grid: {
          color: 'rgba(233, 244, 244, 0.2)',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#E9F4F4',
        },
      },
      tooltip: {
        bodyColor: '#E9F4F4',
        titleColor: '#E9F4F4',
      },
    },
  };
  // [timePeriod, calculateStartTime]);

  const fetchDataAndUpdate = useCallback(async () => {
    await fetchChartData();
    // updateChartOptions();
  }, [fetchChartData]);

  useEffect(() => {
    fetchDataAndUpdate();

    const refreshInterval = getRefreshInterval(refreshFrequency);
    intervalIdRef.current = setInterval(fetchDataAndUpdate, refreshInterval);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [fetchDataAndUpdate, refreshFrequency]);

  return (
    <Box className="latency-forecast">
      <Paper className="network-status-summary paper latency-history">
        <Typography variant="h5">{t('forcastedLatency')}</Typography>
        <div className="chart-container">
          <Line data={chartData} options={chartOptions} />
        </div>
      </Paper>
    </Box>
  );
};

export default ProjectedLatencyChart;