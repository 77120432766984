import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import './Account.css';


const CreatePassword = () => {
  localStorage.removeItem('AuthToken'); // Remove the auth token
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error(t('falseMatchPassword'));
      return;
    } else if (password.length < 8) {
      toast.error(t('invalidPassword'))
      return;
    }
    const body = { token, password };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/new-password`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      // Check if the response is ok (status code 2xx)
      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
      } else {
        // If response is not ok, try to get the error message
        toast.error(t('defaultError'));
      }
    } catch (error) {
      toast.error(t('defaultError'));
    }
  };

  return (
    <div className='account'>
      <div className='section' style={{
        marginTop: "30px",
        display: "inline-block",
        marginLeft: "30%"
      }}>
        <h2>{t('createPassword')}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('password')}
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t('confirmPassword')}
            required
          />
          <button type="submit">{t('setPassword')}</button>
        </form>
        {message && <p>{message}</p>}
        {message === "Password set successfully" && <p><Link to="/login">{t('loginHere')}</Link></p>}
      </div >
    </div>
  );
};

export default CreatePassword;