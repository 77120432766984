import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import io from 'socket.io-client';
import 'xterm/css/xterm.css';
import './Terminal.css'; // Import the CSS file

const TerminalComponent = () => {
  const location = useLocation();
  const { ipAddress } = location.state || { ipAddress: '' };
  
  const terminalRef = useRef(null);
  const term = useRef(null);
  const fitAddon = useRef(new FitAddon());
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const initializeTerminal = () => {
      if (terminalRef.current && !term.current) {
        term.current = new Terminal({
          cursorBlink: true,
          scrollback: 1000,
          tabStopWidth: 16,
        });

        term.current.loadAddon(fitAddon.current);
        term.current.open(terminalRef.current);
        fitAddon.current.fit();

        const handleResize = () => {
          if (term.current) {
            fitAddon.current.fit();
            if (socket) {
              const { cols, rows } = term.current;
              socket.emit('resize', { cols, rows });
            }
          }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial resize

        const newSocket = io(`http://${ipAddress}:3000`);
        setSocket(newSocket);

        newSocket.on('output', (data) => {
          if (term.current) {
            term.current.write(data);
          }
        });

        newSocket.on('connect', () => {
          newSocket.emit('command', 'clear\n');
        });

        term.current.onData((data) => {
          newSocket.emit('command', data);
        });

        return () => {
          window.removeEventListener('resize', handleResize);
          if (term.current) {
            term.current.dispose();
            term.current = null;
          }
          if (newSocket) {
            newSocket.disconnect();
          }
        };
      }
    };

    initializeTerminal();
  }, [ipAddress]);

  return (
    <div className="terminal-window">
      <div className="terminal-header">
        <div className="terminal-buttons">
          <span className="button close"></span>
          <span className="button minimize"></span>
          <span className="button maximize"></span>
        </div>
        <div className="terminal-title">Terminal</div>
      </div>
      <div className="terminal-content">
        <div className="terminal" ref={terminalRef}></div>
      </div>
    </div>
  );
};

export default TerminalComponent;
