import axios from 'axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    console.log("inside useRefreshToken")

    const refresh = async () => {
        console.log("sending refresh request")
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/user/refresh`, {
            withCredentials: true
        });
        setAuth(prev => {
            return { ...prev, AuthToken: response.data.AuthToken, customer_id: response.data.customer_id }
        });
        return response.data.AuthToken;
    }
    return refresh;
};

export default useRefreshToken;
